import {client} from "./client"
import Cookies from 'js-cookie';
interface Bank {
  bankname: string;
}

interface Branch{
  bankCode: string;
  branchName: string;
}

// 動作確認用
export const getBanks = (params: Bank) => {
  return client.get(`/bank_code/search_bank?bankname=${params.bankname}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const getBranches = (params: Branch) =>{
  return client.get(`/bank_code/search_branch?bankcode=${params.bankCode}&branchname=${params.branchName}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}