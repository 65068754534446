import React, { useEffect, useState } from 'react';
import {Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, Button, Grid, Card, CardContent, CardActions, Stack, FormControl, Select, MenuItem, TextField, Pagination } from '@mui/material';
import Loading from '../../components/Loading';
import { ErrorModal } from '../../components/ErrorModal';
import { getProfileContact, getUsers, putForcedWithdrawal, putSuspension, putUnsuspension } from '../../lib/api/admin';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { ConfirmModal } from '../../components/ConfirmModal';

interface Column {
  id: 'createddate' | 'status' | 'type' | 'company' | 'id' | 'sfurl' | 'name' | 'email' | 'mobilephone' | 'detailButton' | 'stopButton' | 'withdrawalButton';
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
  type?: string;
  sortable: boolean;
  zIndex?: number;
  left?: number;
  width?: number;
}

const columns: Column[] = [
  { id: 'createddate', label: 'ポータル登録日', minWidth: 100, align: 'center', sortable: true, zIndex: 90, left: 0, width: 100},
  { id: 'status', label: '退会状況', minWidth: 100, align: 'center', sortable: true, zIndex: 90, left: 100, width: 100},
  { id: 'type', label: '区分', minWidth: 100, align: 'center', sortable: true, zIndex: 90, left: 200, width: 100},
  { id: 'company', label: '法人名/屋号', minWidth: 120, align: 'center', sortable: true, zIndex: 90, left: 300, width: 120},
  { id: 'id', label: '会員ID', minWidth: 80, align: 'center', sortable: true, type: 'date'},
  { id: 'sfurl', label: 'SalesforceのURL', minWidth: 170, align: 'center', sortable: false},
  { id: 'name', label: '名前', minWidth: 170, align: 'center', sortable: true},
  { id: 'email', label: 'メールアドレス', minWidth: 170, align: 'center', sortable: true},
  { id: 'mobilephone', label: '携帯番号', minWidth: 100, align: 'center', sortable: true},
  { id: 'detailButton', label: '会員情報を確認する', minWidth: 170, align: 'center', sortable: false},
  { id: 'stopButton', label: '利用停止', minWidth: 170, align: 'center', sortable: false},
  { id: 'withdrawalButton', label: '強制退会', minWidth: 170, align: 'center', sortable: false}
]

const mobileColumns: Column[] = [
  { id: 'type', label: '区分', minWidth: 0, align: 'center', sortable: true},
  { id: 'company', label: '法人/屋号', minWidth: 120, align: 'center', sortable: true},
  { id: 'id', label: 'ID', minWidth: 100, align: 'center', sortable: true, type: 'date'},
  { id: 'sfurl', label: 'SalesforceのURL', minWidth: 170, align: 'center', sortable: true},
  { id: 'name', label: '名前', minWidth: 170, align: 'center', sortable: true},
  { id: 'email', label: 'メールアドレス', minWidth: 170, align: 'center', sortable: true},
  { id: 'detailButton', label: '会員情報を確認する', minWidth: 170, align: 'center', sortable: false},
  { id: 'stopButton', label: '利用停止', minWidth: 170, align: 'center', sortable: false},
  { id: 'withdrawalButton', label: '強制退会', minWidth: 170, align: 'center', sortable: false}
]

interface Data {
  type: string;
  company: string;
  id: string;
  sfurl: string;
  name: string;
  email: string;
  detailButton: string;
  stopButton: string;
  withdrawalButton: string,
  status: string,
  mobilephone: string,
  createddate: string
}

function createData(
  type: string,
  company: string,
  id: string,
  sfurl: string,
  name: string,
  email: string,
  detailButton: string,
  stopButton: string,
  withdrawalButton: string,
  status: string,
  mobilephone: string,
  createddate: string
): Data{
  return {type, company, id, sfurl, name, email, detailButton, stopButton, withdrawalButton, status, mobilephone, createddate}
}

export const Admin = () =>{
  const [listData, setListData] = useState<Data[]>([]);
  const [orgListData, setOrgListData] = useState<Data[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const navigate = useNavigate();
  const [confirmMessage, setConfirmMessage] = useState<any>();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmHandler, setConfirmHandler] = useState(() =>{});
  const [selectYears, setSelectYears] = useState<string[]>([]);
  const [searchYear, setSearchYear] = useState<any>('1');
  const [searchState, setSearchState] = useState<any>('1');
  const [searchKind, setSearchKind] = useState<any>('1');
  const [searchEmail, setSearchEmail] = useState<any>('');
  const [searchPhone, setSearchPhone] = useState<any>('');
  const [searchLastname, setSearchLastname] = useState<any>('');
  const [searchFirstname, setSearchFirstname] = useState<any>('');
  const [searchCompany, setSearchCompany] = useState<any>('');
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  // ソート関連は以下
  // sort方法
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  //ソート対象
  const [orderBy, setOrderBy] = useState<string>("dueDate");

  //ソート対象、方法の変更
  const handleRequestSort = (event:any, property:any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property:any) => (event:any) => {
    handleRequestSort(event, property);
  };

  //ソート実行
  const stableSort = (array:any, comparator:any) => {
    const stabilizedThis = orgListData.map((el:any, index:any) => [el, index]);
    stabilizedThis.sort((a:any, b:any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    const orgList = stabilizedThis.map((el:any) => el[0]);
    //setOrgListData(orgList);
    // return stabilizedThis.map((el:any) => el[0]);
    return orgList.slice(50 * currentPage, 50 * (currentPage + 1));
  }

  //比較処理
  const descendingComparator = (a:any, b:any, orderBy:any)=> {
    if (a[orderBy] == null) return 1;
    if (b[orderBy] == null ) return -1;
    if(orderBy == 'amount'){
      if(!b[orderBy] || !a[orderBy]){
        return 0;
      }
      if(Number(b[orderBy].replace(/,/g, '')) < Number(a[orderBy].replace(/,/g, ''))){
        return -1;
      }
      if(Number(b[orderBy].replace(/,/g, '')) > Number(a[orderBy].replace(/,/g, ''))){
        return 1;
      }
      return 0;
    }else{
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }
  const getComparator = (order:any, orderBy:any)=> {
    return order === "desc" 
      ? (a:any, b:any) => descendingComparator(a, b, orderBy)
      : (a:any, b:any) => -descendingComparator(a, b, orderBy);
  }

  const handleGetUsers = async (params) => {
    const res = await getUsers(params);
    if (res.status === 200) {
      const lists : Array<Data> = [];
      res.data.records.forEach(rec =>{
        lists.push(createData(
          rec.kind,
          rec.company,
          rec.portaluserId,
          rec.url,
          rec.lastname + rec.firstname,
          rec.email,
          rec.id,
          rec.id,
          rec.id,
          rec.status,
          rec.mobilephone,
          rec.createddate
        ));
      });
      const total = lists.length;
      if(total > 0){
        setTotalPage(Math.floor(total / 50) + (total % 50 > 0 ? 1 : 0));
        setCurrentPage(0);
        setOrgListData(lists);
        setListData(lists.slice(0, 50));
      }
    }
  }

  useEffect(() => {
    handleGetUsers('');
    const thisYear = new Date().getFullYear();
    const thisMonth = new Date().getMonth();
    const listYear : string[] = [];
    for(let y=2024; y <= thisYear; y++){
      let lastMonth = 12;
      if(y == thisYear){
        lastMonth = thisMonth;
      }
      for(let m=1; m <= lastMonth; m++){
        listYear.push(String(y) + String(m).padStart(2, '0'))
      }
    }
    setSelectYears(listYear.reverse());
  }, []);

  const setCookiesForUser = async (uid, email) =>{
    Cookies.set('adminCurrentUserId', uid);
    Cookies.set('_admin_user_email', email);
    const res = await getProfileContact();
    if(res.status === 200){
      const headerName = `${res.data.user.company}`;
      Cookies.set("_admin_contact", JSON.stringify(res.data.record));
      Cookies.set("_admin_header_text", headerName);
    }
    navigate("/admin/top");
  }

  const handleClickDetail = (uid, email) =>{
    setCookiesForUser(uid, email);
  }

  const suspension = async(uid) =>{
    const res = await putSuspension(uid);
    if(res.status === 200){
      const newData = listData.map((d) =>{
        if(d.detailButton == uid){
          d.status = '利用停止';
        }
        return d
      })
      setListData(newData);
    }
    setConfirmModalOpen(false);
  }

  const onClickSuspension = (uid) =>{
    setConfirmMessage(<Typography>利用停止にしますか？</Typography>);
    setConfirmHandler(() => () =>suspension(uid));
    setConfirmModalOpen(true);
  }

  const unsuspension = async(uid) =>{
    const res = await putUnsuspension(uid);
    if(res.status === 200){
      const newData = listData.map((d) =>{
        if(d.detailButton == uid){
          d.status = '登録中（利用停止後）';
        }
        return d;
      })
      setListData(newData);
    }
    setConfirmModalOpen(false);
  }

  const onClickUnsuspension = (uid) =>{
    setConfirmMessage(<Typography>利用再開しますか？</Typography>);
    setConfirmHandler(() => () =>unsuspension(uid));
    setConfirmModalOpen(true);
  }

  const forcedWithdrawal = async(uid) =>{
    const res = await putForcedWithdrawal(uid);
    if(res.status === 200){
      listData.forEach((d) =>{
        if(d.detailButton == uid) d.status = '強制退会';
      })
      setListData(listData);
    }
    setConfirmModalOpen(false);
  }

  const onClickForcedWithdrawal = (uid) =>{
    setConfirmMessage(<Typography>強制退会にしますか？</Typography>);
    setConfirmHandler(() => () =>forcedWithdrawal(uid));
    setConfirmModalOpen(true);
  }

  const handleCancelClick = () =>{
    setConfirmModalOpen(false);
  }

  const onClickSearch = () =>{
    let paramString = ''
    if(searchYear != '1'){
      if(paramString) paramString += '&';
      else paramString += '?'
      paramString += 'createddate=' + searchYear;
    }
    if(searchState != '1'){
      if(paramString) paramString += '&';
      else paramString += '?'
      paramString += 'status=' + searchState;
    }
    if(searchKind != '1'){
      if(paramString) paramString += '&';
      else paramString += '?'
      paramString += 'kind=' + searchKind;
    }
    if(searchEmail){
      if(paramString) paramString += '&';
      else paramString += '?'
      paramString += 'email=' + encodeURIComponent(searchEmail);
    }
    if(searchPhone){
      if(paramString) paramString += '&';
      else paramString += '?'
      paramString += 'phone=' + encodeURIComponent(searchPhone);
    }
    if(searchLastname){
      if(paramString) paramString += '&';
      else paramString += '?'
      paramString += 'lastname=' + encodeURIComponent(searchLastname);
    }
    if(searchFirstname){
      if(paramString) paramString += '&';
      else paramString += '?'
      paramString += 'firstname=' + encodeURIComponent(searchFirstname);
    }
    if(searchCompany){
      if(paramString) paramString += '&';
      else paramString += '?'
      paramString += 'company=' + searchCompany;
    }

    handleGetUsers(paramString);
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) =>{
    setCurrentPage(value - 1);
    setListData(orgListData.slice(50 * (value - 1), 50 * value));
  }

  const getStickyCellStyle = (id, width, zIndex, left) =>{
    if(width){
      return {
        position: "sticky",
        left: left,
        background: "white",
        width: width,
        zIndex: zIndex,
        p: 1,
      }
    }else{
      return {
        p: 1,
      }
    }
  }

  const getStickyHeaderCellStyle = (id, width, zIndex, left) =>{
    if(width){
      return {
        position: "sticky",
        left: left,
        background: "white",
        width: width,
        zIndex: zIndex + 1,
        backgroundColor: "#FFF",
        p: 1,
        fontWeight: 'bold'
      }
    }else{
      return {
        backgroundColor: "#FFF",
        p: 1,
        fontWeight: 'bold'
      }
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
      <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'left'}}>
        <FormControl sx={{width: '210px'}}>
          <Select
            color="secondary"
            defaultValue={'1'}
            sx={{mr: 1}}
            size="small"
            onChange={(event) => setSearchYear(event.target.value)}
          >
            <MenuItem value='1'>登録年月</MenuItem>
            {selectYears.map((year) =>(
              <MenuItem key={year} value={year.slice(0, 4) + '/' + year.slice(4, 6)}>{`${year}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{width: '210px'}}>
          <Select
            defaultValue={'1'}
            color="secondary"
            sx={{mr: 1}}
            size="small"
            onChange={(event) => setSearchState(event.target.value)}
          >
            <MenuItem value='1'>退会状況</MenuItem>
            <MenuItem value='登録中'>登録中</MenuItem>
            <MenuItem value='退会済み'>退会済み</MenuItem>
            <MenuItem value='強制退会'>強制退会済み</MenuItem>
            <MenuItem value='利用停止'>利用停止済み</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{width: '210px'}}>
          <Select
            defaultValue={'1'}
            color="secondary"
            sx={{mr: 1}}
            size="small"
            onChange={(event) => setSearchKind(event.target.value)}
          >
            <MenuItem value='1'>区分</MenuItem>
            <MenuItem value='個人事業主'>個人</MenuItem>
            <MenuItem value='法人'>法人</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{width: '210px'}}>
          <TextField
            margin="normal"
            color="secondary"
            sx={{mt:1, mb:1}}
            placeholder='メールアドレス'
            size="small"
            onBlur={(e) =>{
              setSearchEmail(e.target.value);
            }}
          />
        </FormControl>
        </Stack>
        <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'left'}}>
        <FormControl sx={{width: '210px'}}>
          <TextField
            margin="normal"
            color="secondary"
            sx={{mt:1, mb:1, mr: 1}}
            placeholder='携帯番号'
            size="small"
            onBlur={(e) =>{
              setSearchPhone(e.target.value);
            }}
          />
        </FormControl>
        <FormControl sx={{width: '210px'}}>
          <TextField
            margin="normal"
            color="secondary"
            sx={{mt:1, mb:1, mr: 1}}
            placeholder='代表者(姓)'
            size="small"
            onBlur={(e) =>{
              setSearchLastname(e.target.value);
            }}
          />
        </FormControl>
        <FormControl sx={{width: '210px'}}>
          <TextField
            margin="normal"
            color="secondary"
            sx={{mt:1, mb:1, mr: 1}}
            placeholder='代表者(名)'
            size="small"
            onBlur={(e) =>{
              setSearchFirstname(e.target.value);
            }}
          />
        </FormControl>
        <FormControl sx={{width: '210px'}}>
          <TextField
            margin="normal"
            color="secondary"
            sx={{mt:1, mb:1, mr: 1}}
            placeholder='会社名'
            size="small"
            onBlur={(e) =>{
              setSearchCompany(e.target.value);
            }}
          />
        </FormControl>
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>{onClickSearch()}}
          sx={{borderRadius: 5, fontWeight: 'bold', mr: 1}}
          >
          検索
        </Button>
      </Stack>
      </Box>
      <Box sx={{ width: '100%', display: {xs: 'block', md: 'block'}}}>
      <TableContainer sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            {columns.map((column) =>(
                column.id != 'detailButton' && column.id != 'stopButton' && column.id != 'withdrawalButton'?
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth}}
                    sortDirection={orderBy === column.id ? order : 'desc'}
                    onClick={createSortHandler(column.id)}
                    sx={getStickyHeaderCellStyle(column.id, column.width, column.zIndex, column.left)}
                  >
                    {column.label}
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                      sx={{backgroundColor: "#FFF", p: 1, fontWeight: 'bold'}}
                    ></TableSortLabel>
                  </TableCell>
                : <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth}}
                    sortDirection={orderBy === column.id ? order : 'desc'}
                    sx={{backgroundColor: "#FFF", p: 1, fontWeight: 'bold'}}
                  >
                    {column.label}
                  </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(listData, getComparator(order, orderBy))
              .map((row, index) =>{
                return (
                  <TableRow hover tabIndex={-1} key={row.detailButton + '_' + index}>
                    {columns.map((column, idx) =>{
                      const value = row[column.id];
                      return(
                        <TableCell key={column.id + '_' + idx} align={column.align} sx={getStickyCellStyle(column.id, column.width, column.zIndex, column.left)}>
                          {column.id == 'detailButton' ?
                            <Button
                              variant='contained'
                              color="warning"
                              onClick={(e) => handleClickDetail(value, row['email'])}
                              sx={{borderRadius: 5, fontWeight: 'bold'}}
                            >詳細を見る</Button>
                            :column.id == 'stopButton' && (row['status'] != '強制退会' && row['status'] != '利用停止')?
                            <Button
                              variant='contained'
                              onClick={(e) =>onClickSuspension(value)}
                              sx={{borderRadius: 5, fontWeight: 'bold'}}
                            >利用停止</Button>
                            :column.id == 'stopButton' && row['status'] == '利用停止'?
                            <Button
                              variant='contained'
                              color='info'
                              onClick={(e) =>onClickUnsuspension(value)}
                              sx={{borderRadius: 5, fontWeight: 'bold'}}
                            >利用再開</Button>
                            :column.id == 'stopButton' && row['status'] == '強制退会'?
                            <></>
                            :column.id == 'withdrawalButton' && row['status'] != '強制退会'?
                            <Button
                              variant='contained'
                              color='secondary'
                              onClick={(e) =>onClickForcedWithdrawal(value)}
                              sx={{borderRadius: 5, fontWeight: 'bold'}}
                            >強制退会</Button>
                            :column.id == 'withdrawalButton' && row['status'] == '強制退会'?
                            <></>
                            :column.id == 'sfurl'?
                            <Button
                              variant='contained'
                              onClick={(e) =>window.open(value, '_blank')}
                              sx={{borderRadius: 5, fontWeight: 'bold', backgroundColor: '#036AFD', color: "#FFFFFF"}}
                            >SFへ</Button>
                            // <Box sx={{wordBreak: 'break-all', maxWidth: column.minWidth, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            //   <Box component='a' target='_blank' title={value} href={value}>
                            //     {value}
                            //   </Box>
                            // </Box>
                            :
                            value }
                        </TableCell>
                      )
                    })}
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
      <Stack spacing={2}>
        <Pagination color="primary" count={totalPage} page={currentPage + 1} onChange={handlePageChange}/>
      </Stack>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
      <ConfirmModal message={confirmMessage} modalOpen={confirmModalOpen} cancelClick={handleCancelClick} okClick={confirmHandler}></ConfirmModal>
      {/* <a href='https://stg-api.gmo-aozora.com/ganb/api/auth/v1/authorization?response_type=code&scope=private%3Aaccount%20private%3Avirtual-account%20private%3Atransfer%20private%3Abulk-transfer%20openid%20offline_access&redirect_uri=https%3A%2F%2Fbetrading--backup.sandbox.my.salesforce.com%2Fservices%2Fauthcallback%2Fgmo_dev&client_id=D1eDuooVYUKmYTMI'>GMOコード</a> */}
    </Box>
  );
}