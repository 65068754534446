import {client, logoutClient} from "./client";
import Cookies from "js-cookie";

// ログインユーザーの取得
export const getAdminUser = () => {
  if (
    !Cookies.get("_admin_access_token") ||
    !Cookies.get("_admin_client") ||
    !Cookies.get("_admin_uid")
  )
    return;

  return client.get("/admin_auth/admin_user/sessions", {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
};

export const getSession = () =>{
  return client.get("/admin_auth/admin_user/session");
}

// サインアウト
export const adminSignOut = () => {
  return logoutClient.delete("/admin_users/sign_out", {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
};