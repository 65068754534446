import {client} from "./client"
import Cookies from 'js-cookie';

export const getTodos = () => {
  return client.get(`/todo`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  })
}

export const getOppTodo = (id:string) =>{

  return client.get(`/todo?ids=${id}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  })
}