import React, { useEffect, useState } from 'react';
import { Box, Button, Typography} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { getProfile } from '../../lib/api/profile';

export const FactoringSuccess = () =>{
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const [isCompletedProfile, setIsCompletedProfile] = useState(true);
  const [isCompletedEkyc, setIsCompletedEkyc] = useState(true);
  const [message, setMessage] = useState('');
  const [profileId, setProfileId] = useState();
  
  const handleGetInfo = async () =>{
    try{
      const ret = await getProfile();
      if(ret.status == 200){
        const profile = ret.data.record;
        if(profile.kind == '法人'){
          const fields = [
            'company', 'companynameFurigana', 'postalcode', 'state', 'city', 'street', 'position', 
            'lastname', 'firstname', 'lastnamekana', 'firstnamekana', 'raPostalcode', 'raState',
            'raCity', 'raStreet', 'email', 'mobilephone', 'seibetsu', 'birthdate', 'bankname',
            'branchname', 'accounttype', 'accountnumber', 'accountholder', 'accountnameKana',
            'emergencycontactName', 'emergencycontactFurigana', 'emergencycontactRelationship', 'emergencycontactPhoneno'
          ];
          const values = fields.map(field => profile[field]).filter(v => v);
          if(values.length == fields.length) setIsCompletedProfile(true);
          else setIsCompletedProfile(false);
        }else if(profile.kind == '個人事業主'){
          const fields = [
            'company', 'companynameFurigana', 'businessPostalcode', 'businessState', 'businessCity', 'businessStreet', 'position', 
            'lastname', 'firstname', 'lastnamekana', 'firstnamekana', 'raPostalcode', 'raState',
            'raCity', 'raStreet', 'email', 'mobilephone', 'seibetsu', 'birthdate', 'bankname',
            'branchname', 'accounttype', 'accountnumber', 'accountholder', 'accountnameKana',
            'emergencycontactName', 'emergencycontactFurigana', 'emergencycontactRelationship', 'emergencycontactPhoneno'
          ];
          const values = fields.map(field => profile[field]).filter(v => v);
          if(values.length == fields.length) setIsCompletedProfile(true);
          else setIsCompletedProfile(false);
        }
        if(!profile.identification || profile.identification == '否認') setIsCompletedEkyc(false);
        else setIsCompletedEkyc(true);
        setProfileId(profile.id);
        setIsLoad(true);
      }
    }catch(e){
      console.log(e);
      navigate('/');
    }
  }

  useEffect(() =>{
    handleGetInfo();
  }, []);

  useEffect(() =>{
    if(!isLoad) return;
    if(isCompletedProfile && isCompletedEkyc) setMessage('審査が完了するまでお待ちください。')
    else if(!isCompletedProfile && isCompletedEkyc) setMessage('審査が完了するまでお待ちください。')
    else if(isCompletedProfile && !isCompletedEkyc) setMessage('審査が完了するまでお待ちください。')
    else if(!isCompletedProfile && !isCompletedEkyc) setMessage('審査が完了するまでお待ちください。')
  }, [isLoad]);

  const onClickCancel = () =>{
    navigate("/top");
  }

  const onClickProfile = () =>{
    navigate(`/myprofile/${profileId}`);
  }

  const onClickEkyc = () =>{
    navigate("/ekyc");
  }

  const onClickIndex = () =>{
    navigate('/status_list');
  }

  return (
    <Box
      sx={{
        backgroundColor: '#F0EFEA',
        width: {md: '60%'},
        height: {md: '200px'},
        margin: '0 auto',
        borderRadius: '5px',
        minWidth: {md: '570px'},
        mt: {md: 10, xs: 2},
        pr: 2,
        pl: 2,
        pt: 1,
        pb: 1
      }}
    >
      <Typography sx={{fontWeight: 'bold', fontSize: 17, pt: 5}}>
        審査の申し込みが完了しました。
      </Typography>
      <Typography>
        {message}
      </Typography>
      <Box sx={{mt: 3}}>
        <Button
          variant="contained"
          color="primary"
          disabled={isCompletedProfile}
          onClick={() =>{onClickProfile()}}
          sx={{borderRadius: 5, minWidth: '155px', width: {md: '25%', xs: '100%'}, fontWeight: 'bold', mb: {xs: 1, md: 2}, mr: 1}}
        >
          会員情報の入力
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isCompletedEkyc}
          onClick={() =>{onClickEkyc()}}
          sx={{borderRadius: 5, minWidth: '100px', width: {md: '25%', xs: '100%'}, fontWeight: 'bold', mb: {xs: 1, md: 2}, mr: 1}}
        >
          かんたん本人確認
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>{onClickIndex()}}
          sx={{borderRadius: 5, minWidth: '155px', width: {md: '25%', xs: '100%'}, fontWeight: 'bold', mb: {xs: 1, md: 2}, mr: 1}}
        >
          申請履歴一覧
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>{onClickCancel()}}
          sx={{borderRadius: 5, minWidth: '155px', width: {md: '25%', xs: '100%'}, fontWeight: 'bold', mb: {xs: 1, md: 2}, mr: 1}}
        >
          ホームに戻る
        </Button>
      </Box>
    </Box>
  );
}