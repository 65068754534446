import {client} from "./client"
import Cookies from 'js-cookie';

// 動作確認用
export const getProfile = () => {
  const id = Cookies.get('currentUserId');
  return client.get(`/profile/${id}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  })
}

export const updateProfile = (params: any) =>{
  const formData = new FormData();
  Object.keys(params).forEach(key =>{
    if(key == 'files'){
      if(params[key] && params[key].length > 0){
        for(let i=0; i < params[key].length; i++){
          formData.append('profile[files][]', params[key][i]);
        }
        formData.append('profile[supportingdocuments2]', '提出済み');
      }
    }else{
      formData.append(`profile[${key}]`, params[key]);
    }
  })
  const id = Cookies.get('currentUserId');
  return client.put(`/profile/${id}`, formData, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const updateProfileSupportingdocuments = (supportingdocuments: string) =>{
  const formData = new FormData();
  formData.append('profile[id]', Cookies.get('currentUserId'));
  formData.append('profile[supportingdocuments2]', supportingdocuments);
  return client.put(`/profile/${Cookies.get('currentUserId')}/update_supportingdocuments2`, formData, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const sendProfileSms = (mobilephone: string) =>{
  const formData = new FormData();
  formData.append('mobilephone', mobilephone);
  return client.post('/profile/send_sms', formData, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const getProfileContact = () =>{
  return client.get('/profile/contact', {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const deleteProfile = (params: any) =>{
  const id = Cookies.get('currentUserId');
  return client.patch(`/profile/${id}/delete`, params, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}