import React from "react";
import { Modal, Box, Button, Typography} from '@mui/material';

const phraseStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

export const ConfirmModal = (props) =>{
  return (
    <Modal
      open={props.modalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={phraseStyle}>
        <Box>
          {props.message}
        </Box>
        {props.buttonLength == 1 ?
          <Button
            variant='contained'
            color='warning'
            onClick={props.closeClick}
            sx={{borderRadius: 5, width: '100%', fontWeight: 'bold', mt: {xs: 0, md: 2}}}
          >
            閉じる
          </Button>
          :
          <Box sx={{textAlign: 'center'}}>
            <Button
              variant='contained'
              color='warning'
              onClick={props.cancelClick}
              sx={{borderRadius: 5, width: '45%', fontWeight: 'bold', mt: {xs: 0, md: 2}, mr: 1}}
            >
              いいえ
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={props.okClick}
              sx={{borderRadius: 5, width: '45%', fontWeight: 'bold', mt: {xs: 0, md: 2}}}
            >
              はい
            </Button>
          </Box>
        }
      </Box>
    </Modal>
  )
}