import React from 'react';
import { Box, Button, Typography} from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";

export const ProfileSuccess = () =>{
  const navigate = useNavigate();
  const {id} = useParams();

  const onClickCancel = () =>{
    navigate("/myprofile/" + id);
  }

  const onClickFactoring = () =>{
    navigate("/factoring/top");
  }

  const onClickEkyc = () =>{
    navigate("/ekyc");
  }

  return (
    <Box
      sx={{
        backgroundColor: '#F0EFEA',
        width: {md: '60%'},
        height: {md: '200px'},
        margin: '0 auto',
        borderRadius: '5px',
        minWidth: {md: '570px'},
        mt: {md: 10, xs: 2},
        pr: 2,
        pl: 2,
        pt: 1,
        pb: 1
      }}
    >
      <Typography sx={{fontWeight: 'bold', fontSize: 17, pt: 5}}>
        会員情報の変更が完了しました。
      </Typography>
      <Box sx={{mt: 3}}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>{onClickCancel()}}
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, minWidth: {md: '170px'}}}
        >
          会員情報を確認する
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>{onClickFactoring()}}
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, minWidth: {md: '170px'}}}
        >
          審査の申し込み
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>{onClickEkyc()}}
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, minWidth: {md: '170px'}}}
        >
          かんたん本人確認
        </Button>
      </Box>
    </Box>
  );
}