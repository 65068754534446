import {client} from "./client"
import Cookies from 'js-cookie';

export const getCollections = (span: number) => {
  return client.get(`/collection?span=${span}}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  })
}

export const getCollectionNotifications = () => {
  return client.get("/collection/notifications", {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  })
}

export const updateCollectionDate = (ids: []) => {
  const formData = new FormData();
  formData.append('ids', ids.join(','));
  return client.patch("/collection/update_all", formData, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  })
}
