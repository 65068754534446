import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

function Loading({ inverted = true, content="Loading..."}) {
  return (
    <Backdrop open={inverted} sx={{zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default Loading;