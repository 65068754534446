import React, { useEffect, useState} from "react";
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import ImageButton from "../../../components/ImageButton";
import { getProfile } from "../../../lib/api/admin";

export const AdminProfileTop = () =>{
  const navigate = useNavigate();
  const [isDisableEkyc, setIsDisableEkyc] = useState(true);

  const handleGetProfile = async() =>{
    try{
      const res = await getProfile();
      if(res.status === 200){
        const rec = res.data.record;
        const identification = res.data.record.identification;
        const recentIdentification = res.data.record.recentIdentification?.tdStateC;
        const user = res.data.record;
        // 連続否認の場合もdisable
        const thirdFlg = res.data.record.threeconsecutivedenials;
        if(thirdFlg || identification == '本人確認済み' || identification == '確認中') setIsDisableEkyc(true);
        else if((identification == null || identification == '-' || identification == '否認') && (recentIdentification == 'document_submitted' || recentIdentification == 'in_progress')) setIsDisableEkyc(true);
        else if(!user.raPostalcode || !user.raState || !user.raCity || !user.raStreet) setIsDisableEkyc(true);
        else setIsDisableEkyc(false);
      }
    }catch(e){
      console.log(e);
      navigate('/admin/top');
    }
  }

  useEffect(() =>{
    handleGetProfile();
  }, []);

  const onClickProfileInfo = () =>{
    // TODO: ログイン機能実装後は、PortalUserのIDを指定する
    navigate("/admin/myprofile/" +  Cookies.get('adminCurrentUserId'));
  }

  const onClickSalesToInfo = () =>{
    navigate("/admin/client_list");
  }

  const onClickEkyc = () =>{
    return;
  }

  return (
      <Box sx={{
        display: {
          md: 'flex'
        },
        height: {
          md: '80%'
        },
        justifyContent: {
          md: 'center'
        },
        alignItems:{
          md: 'center'
        },
        p: {xs: 1, md: 0},
        borderRadius: 2
      }}>
        {Cookies.get('isSignedIn')? 
          <Box sx={{ pl: 0, ml: -2, mb: 1, mt: -4, textAlign: 'left', backgroundColor: '#FCD19C', width: '100%', display: {md: 'none', xs: 'block'}}}>
            <Typography  sx={{ color: '#000000', display: {md: 'none', xs: 'block'}, overflow: 'hidden', fontSize: '12px'}}>会員ID</Typography>
            <Typography sx={{ color: '#000000', display: {md: 'none', xs: 'block'}, overflowWrap: 'anywhere', fontSize: '12px', width: '85vw'}}>{Cookies.get('_uid')}</Typography>
          </Box>
          :
          <></>
        }
        <ImageButton onClick={onClickProfileInfo} imgUrl="/icons/icon_on_profile.svg" buttonText="会員情報の登録・変更はこちら" buttonTitle="会員情報"/>
        <ImageButton onClick={onClickSalesToInfo} imgUrl="/icons/icon04.svg" buttonText="売掛先情報の登録はこちら" buttonTitle="売掛先情報"/>
        <ImageButton onClick={onClickEkyc} imgUrl="/icons/ekyc_icon.svg" buttonText="本人確認がお済みでない方はこちら" buttonTitle="かんたん本人確認" disabled={isDisableEkyc}/>
      </Box>
  );
}
