import {client} from "./client"
import Cookies from 'js-cookie';

export const sendErrorMail = (url: string, message: string, title: string) =>{
  const formData = new FormData();
  formData.append('url', url);
  formData.append('message', message);
  formData.append('title', title);
  return client.post('/error_mail', formData, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}