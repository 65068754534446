import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

export const PageTitle = () =>{
  const [pageName, setPageName] = useState('');
  const location = useLocation();

  useEffect(() => {
    let pathname = location.pathname;
    if(pathname.indexOf('/myprofile/edit/authentication') >= 0){
      pathname='/myprofile/edit/authentication';
    }else if(pathname.indexOf('/myprofile/edit/completed') >= 0){
      pathname='/myprofile/edit/completed';
    }else if(pathname=='/profile' || pathname.indexOf('/myprofile') >= 0){
      pathname='/profile';
    }else if(pathname.indexOf('/client_info/completed') >= 0){
      pathname="/client_info/completed";
    }else if(pathname.indexOf('/status_detail') >= 0){
      pathname="/status_detail";
    }else if(pathname.indexOf('/file_addition') >= 0){
      if(pathname.indexOf('list') < 0 && pathname.indexOf('confirm') < 0 && pathname.indexOf('completed') < 0){
        pathname = '/file_addition';
      }
    }

    switch(pathname){
      case '/login':
        setPageName('ログイン');
        break;
      case '/login/password_resetting':
      case '/login/password_resetting-2':
      case '/login/password_resetting-3':
        setPageName('パスワード設定');
        break;
      case '/login/password_resetting/completed':
        setPageName('パスワード変更完了');
        break;
      case '/sign_up/completed':
        setPageName('メールアドレス登録完了');
        break;
      case '/sign_up':
        setPageName('メールアドレス登録');
        break;
      case '/sign_up/info':
      case '/member':
        setPageName('会員登録フォーム');
        break;
      case '/sign_up_2':
        setPageName('登録フォーム');
        break;
      case '/':
      case '/top':
      case '/admin/top':
        setPageName('ホーム');
        break;
      case '/profile':
      case '/admin/profile':
        setPageName('会員情報');
        break;
      case '/myprofile/edit/completed':
        setPageName('変更完了');
        break;
      case '/client':
      case '/admin/client':
        setPageName('売掛先情報');
        break;
      case '/client_register':
      case '/admin/client_register':
        setPageName('新規売掛先の登録');
        break;
      case '/client_info/completed':
        setPageName('登録完了');
        break;
      case '/client_list':
      case '/admin/client_list':
        setPageName('登録済み売掛先情報');
        break;
      case '/factoring/top':
      case '/admin/factoring/top':
        setPageName('審査の申し込み');
        break;
      case '/factoring/app/new':
      case '/admin/factoring/app/new':
        setPageName('審査の申し込み');
        break;
      case '/factoring/app/confirm':
      case '/admin/factoring/app/confirm':
        setPageName('申請内容');
        break;
      case '/factoring/app/draft':
      case '/admin/factoring/app/draft':
        setPageName('下書き');
        break;
      case '/factoring/app_list':
      case '/admin/factoring/app_list':
        setPageName('申請履歴');
        break;
      case '/factoring/app-2/confirm':
      case '/admin/factoring/app-2/confirm':
        setPageName('申請内容');
        break;
      case '/file_addition_list':
      case '/admin/file_addition_list':
        setPageName('追加で書類提出');
        break;
      case '/status_list':
      case '/admin/status_list':
        setPageName('申請履歴');
        break;
      case '/status_detail':
      case '/admin/status_detail':
        setPageName('申請内容詳細');
        break;
      case '/file_addition':
      case '/admin/file_addition':
        setPageName('書類提出フォーム');
        break;
      case '/payment_list':
      case '/admin/payment_list':
        setPageName('入金予定日一覧');
        break;
      case '/terms_of_service':
      case '/admin/terms_of_service':
        setPageName('利用規約');
        break;
      case '/faq':
      case '/admin/faq':
        setPageName('よくある質問');
        break;
      case '/delete':
      case '/admin/delete':
        setPageName('退会手続きフォーム');
        break;
      case '/delete/completed':
      case '/admin/delete/completed':
        setPageName('退会完了');
        break;
      case '/privacy':
      case '/admin/privacy':
        setPageName('プライバシーポリシー');
        break;
      default:
        setPageName('');
    }
  }, [location.pathname])

  return (
    <Box >
      <Typography sx={{fontSize: 20, mb: 1, textAlign: 'left'}}>
        {pageName}
      </Typography>
      {Cookies.get('isSignedIn') && location.pathname == '/profile'? 
        <Box sx={{ pl: 0, mb: -2, textAlign: 'left', width: '100%', display: {md: 'block', xs: 'none'}}}>
          <Typography  sx={{ color: '#000000', display: {md: 'block', xs: 'none'}, overflow: 'hidden', fontSize: '12px'}}>会員ID</Typography>
          <Typography sx={{ color: '#000000', display: {md: 'block', xs: 'none'}, overflowWrap: 'anywhere', fontSize: '12px', width: '85vw'}}>{Cookies.get('_email')}</Typography>
        </Box>
        : Cookies.get('_admin_access_token') && location.pathname == '/admin/profile' ?
        <Box sx={{ pl: 0, mb: -2, textAlign: 'left', width: '100%', display: {md: 'block', xs: 'none'}}}>
          <Typography  sx={{ color: '#000000', display: {md: 'block', xs: 'none'}, overflow: 'hidden', fontSize: '12px'}}>会員ID</Typography>
          <Typography sx={{ color: '#000000', display: {md: 'block', xs: 'none'}, overflowWrap: 'anywhere', fontSize: '12px', width: '85vw'}}>{Cookies.get('_admin_user_email')}</Typography>
        </Box>
        :<></>
      }
    </Box>
  );
};