import {  Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function ImageButton({onClick, buttonTitle='', imgUrl='', buttonText='', disabled=false, disableColor="rgb(147, 147, 147)", disableBorder='1px solid #000', arrowEnable=true}) {
  const navigate = useNavigate();
  
  const onClickLink = (event) =>{
    onClick(event);
  }

  return (
    <>
    {disabled ?
      <Box
        sx={[
          {
            border: disableBorder,
            width: {xs: '100%', md: "33%"},
            minHeight: {md: arrowEnable ? '220px' : '180px', xs: '50px'},
            maxHeight: arrowEnable ? '230px' : '180px',
            borderRadius: 5,
            position: 'relative',
            mr: {md: 1},
            mt: {xs: 2},
            backgroundColor: disableColor
          }
        ]}
      >
        <Box sx={{display: {xs: 'none', md: 'block'}}}>
          <Grid>
            <Typography sx={{fontWeight: 'bold', pt: 2}}>{buttonTitle}</Typography>
          </Grid>
          <Grid>
            <Box component="img" src={imgUrl} sx={{position: 'absolute', left: 'calc((100% - 125px)/2)', top: imgUrl == '/icons/icon_on_profile.svg' ? 40 : 60, width: {xs: "125px", md: "125px"}}}/>
          </Grid>
          {arrowEnable ?
          <Grid sx={{position: 'absolute', bottom: 10, width: '100%'}}>
            <Typography sx={{fontWeight: 'bold', fontSize: '13px'}}>{buttonText}<Box component="img" src="/icons/icon_arrow.svg" sx={{transform: "rotate(-90deg)", pr: 0.6}}/></Typography>
          </Grid>
          :
          <></>
          }
        </Box>
        <Box sx={{display: {xs: 'flex', md: 'none'}, margin: 'auto'}}>
          <Box sx={{m: 'auto', pt: '25px', pb: '25px'}}>
            {buttonTitle == '過去申請をコピーして審査の申し込み' ?
            <Typography sx={{fontWeight: 'bold', fontSize: '20px'}}>過去申請をコピーして<br/>審査の申し込み</Typography>
            :
            <Typography sx={{fontWeight: 'bold', fontSize: '20px'}}>{buttonTitle}</Typography>
            }
            {buttonText == '過去の申請をコピーして審査を申し込む場合はこちら' ?
            <Typography sx={{fontSize: '14px'}}>過去の申請をコピーして<br/>審査を申し込む場合はこちら</Typography>
            :
            <Typography sx={{fontSize: '14px'}}>{buttonText}</Typography>
            }
          </Box>
          {arrowEnable ?
          <Box component="img" src="/icons/icon_arrow.svg" sx={{
            transform: "rotate(-90deg)", 
            position: 'absolute',
            top: 'calc(50% - 5.5px)',
            right: '10px'
          }}/>
          :
          <></>
          }
        </Box>
      </Box>
      :
      <Box
        onClick={onClickLink}
        sx={[
          {
            border: '1px solid #000',
            width: {xs: '100%', md: "33%"},
            minHeight: {md: arrowEnable ? '220px' : '180px', xs: '50px'},
            maxHeight: arrowEnable ? '230px' : '180px',
            borderRadius: 5,
            cursor: 'pointer',
            position: 'relative',
            mr: {md: 1},
            mt: {xs: 2},
            boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.15)',
            backgroundColor: '#FFFFFF'
          },
          {'&:hover': {
            backgroundColor: '#F0F0F0'
          }}
        ]}
      >
        <Box sx={{display: {xs: 'none', md: 'block'}}}>
          <Grid>
            <Typography sx={{fontWeight: 'bold', pt: 2}}>{buttonTitle}</Typography>
          </Grid>
          <Grid>
            <Box component="img" src={imgUrl} sx={{position: 'absolute', left: 'calc((100% - 125px)/2)', top: imgUrl == '/icons/icon_on_profile.svg' ? 40 : 60, width: {xs: "125px", md: "125px"}}}/>
          </Grid>
          {arrowEnable ?
          <Grid sx={{position: 'absolute', bottom: 10, width: '100%'}}>
            <Typography sx={{fontWeight: 'bold', fontSize: '13px'}}>{buttonText}<Box component="img" src="/icons/icon_arrow.svg" sx={{transform: "rotate(-90deg)", pr: 0.6}}/></Typography>
          </Grid>
          :
          <></>
          }
        </Box>
        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
          <Box sx={{m: 'auto', pt: '25px', pb: '25px'}}>
            {buttonTitle == '過去申請をコピーして審査の申し込み' ?
            <Typography sx={{fontWeight: 'bold', fontSize: '20px'}}>過去申請をコピーして<br/>審査の申し込み</Typography>
            :
            <Typography sx={{fontWeight: 'bold', fontSize: '20px'}}>{buttonTitle}</Typography>
            }
            {buttonText == '過去の申請をコピーして審査を申し込む場合はこちら' ?
            <Typography sx={{fontSize: '14px'}}>過去の申請をコピーして<br/>審査を申し込む場合はこちら</Typography>
            :
            <Typography sx={{fontSize: '14px'}}>{buttonText}</Typography>
            }
          </Box>
          {arrowEnable ?
          <Box component="img" src="/icons/icon_arrow.svg" sx={{
            transform: "rotate(-90deg)", 
            position: 'absolute',
            top: 'calc(50% - 5.5px)',
            right: '10px'
          }}/>
          :
          <></>
          }
        </Box>
      </Box>
    }
    </>
  )
}

export default ImageButton;