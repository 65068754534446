import React, { useRef } from 'react';
import { Button, TextField, InputLabel, FormControl, Grid, FormGroup, Autocomplete, Box, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Cookies from 'js-cookie';
import { updateLead } from '../../lib/api/lead';
import Loading from "../../components/Loading";
import { Corporate, TextFieldWButton, TextFieldWText } from '../../common/profile';
import { getCorporates } from '../../lib/api/corporate';
import { ErrorModal } from '../../components/ErrorModal';

const validationRules = {
  kind:{
    required: '事業形態を入力してください。'
  },
  company:{
    validate: (value, formValues) =>{
      if(!value){
        if(formValues.kind == '法人'){
          return '会社名を入力してください。'
        }else{
          return '屋号を入力してください。'
        }
      }
    }
  },
  companynameFurigana:{
    validate: (value, formValues) =>{
      if(!value){
        if(formValues.kind == '法人'){
          return '会社名(フリガナ)を入力してください'
        }else{
          return '屋号(フリガナ)を入力してください'
        }
      }
      const textValidPattern = /^[ァ-ン－ー—‐ｰ−–．／（）　]*$/u;
      return textValidPattern.test(value) || '使用できる文字はカタカナ、記号(ー．／（）)、全角スペースです。';
    }
  },
  mobilephone: {
    required: '携帯番号を入力してください',
    validate: (value, formValues) =>{
      const mobilephonePattern = /^(070|080|090)-?\d{4}-?\d{4}$/;
      return mobilephonePattern.test(value) || '携帯番号の形式が合っていません。';
    }
  }
}

export const SignupInfo = () =>{
  const { control, handleSubmit, setValue, getValues} = useForm({defaultValues: {kind: '法人', company: '', companynameFurigana: '', mobilephone: '', corporatenumberAccount: '', postalcode: '', state: '未設定', city: '', street: ''}});
  const [kindState, setKind] = useState('法人');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [corporates, setCorporates] = useState<Corporate[]>([]);
  const corpRef = useRef<HTMLInputElement>(null);
  const [acValue, setAcValue] = useState('');
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const onSubmit = async(evt) =>{
    if(isLoading) return;
    setIsLoading(true);
    try{
      evt['email'] = Cookies.get('_email');
      const res = await updateLead(evt);
      setIsLoading(false);
      if(res.status == 200){
        const headerName =  `${evt.company}`;
        const users = Cookies.get("_users") ? JSON.parse(Cookies.get("_users")) : [];
        const indx = users.findIndex((u) => u._uid == res.headers['uid']);
        users[indx]._header_text = headerName;
        Cookies.set("_users", JSON.stringify(users));
        Cookies.set("_header_text", headerName);
        navigate('/sign_up/password');
      }else{
        setErrorMessage(<Typography>処理に失敗しました。再度、次へをクリックしてください。</Typography>);
        handleOpenErrorModal();
      }
    }catch(e){
      setIsLoading(false);
      setErrorMessage(<Typography>{e.response.data.errors[0]}</Typography>);
      handleOpenErrorModal();
    }
  }

  const handleChangeKind = (value) =>{
    if(!value) return;
    setValue('kind', value);
    setKind(value);
    setValue('company', '');
    setValue('companynameFurigana', '');
    setValue('corporatenumberAccount', '');
    setValue('postalcode', '');
    setValue('state', '未設定');
    setValue('city', '');
    setValue('street', '');
  }

  const searchCompany = async() =>{
    const values = getValues();
    try{
      const ret = await getCorporates({name: values.company});
      const count = parseInt(ret.data.records.corporations.count);
      if(count > 1){
        setCorporates(ret.data.records.corporations.corporation);
      }else if(count == 1){
        setCorporates([ret.data.records.corporations.corporation]);
      }else{
        setCorporates([{
          name: '',
          corporateNumber: '',
          furigana: '',
          postCode: '',
          prefectureName: '',
          cityName: '',
          streetNumber: ''
        }])
      }
      setAcValue(values.company);
    }catch(e){
      setErrorMessage(<Typography>{e.response.data.message}</Typography>);
      handleOpenErrorModal();
    }
  }

  return (
    <Box sx={{pr: {xs: 1, md: 2}, pl: {xs: 1, md: 2}, pb: 2}}>
      <form id="lead-create-form" onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        borderLeft: "5px solid #D16960",
        textAlign: 'left'
      }}>
        <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}></Typography>
        <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>会社の基本情報をご入力ください。</Typography>
        <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}></Typography>
      </Box>
      <Box
          sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
        >
          <Controller
            control={control}
            name='kind'
            rules={validationRules['kind']}
            render={({ field, fieldState }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    事業形態
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <FormControl fullWidth margin='normal' error={fieldState.invalid} sx={{mt: {xs: 0, md: 2}}}>
                    <RadioGroup
                      row
                      {...field}
                      onChange={(evt) =>{handleChangeKind(evt.target.value)}}
                    >
                      <FormControlLabel value="法人" control={<Radio />} label="法人" />
                      <FormControlLabel value="個人事業主" control={<Radio />} label="個人事業主" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name='company'
            rules={validationRules['company']}
            render={({ field, fieldState }) => (
              kindState == '法人' ?
              <Autocomplete
                freeSolo
                options={corporates}
                getOptionLabel={(option: any) => option.name || ''}
                renderOption={(props, option) => (
                  <Box key={option?.corporateNumber} >
                    <Typography {...props}>
                      {option?.name} ({option?.prefectureName}{option?.cityName}{option?.streetNumber})
                    </Typography>
                  </Box>
                )}
                value={corporates.length == 1 ? corporates[0] : ''}
                onChange={(_event, value: any | null) => {
                  if(value){
                    setValue('company', value?.name);
                    setValue('companynameFurigana', value?.furigana);
                    setValue('corporatenumberAccount', value?.corporateNumber);
                    setValue('postalcode', value?.postCode);
                    setValue('state', value?.prefectureName);
                    setValue('city', value?.cityName);
                    setValue('street', value?.streetNumber);
                  }
                }}
                onInputChange={(_event, value, reason) =>{
                  if(reason == 'clear' || reason == 'input'){
                    setValue('company', '');
                    setValue('companynameFurigana', '');
                    setValue('corporatenumberAccount', '');
                    setValue('postalcode', '');
                    setValue('state', '未設定');
                    setValue('city', '');
                    setValue('street', '');
                  }
                  if(reason == 'reset' && value && corporates.length == 1){
                    setValue('company', corporates[0].name);
                    setValue('companynameFurigana', corporates[0].furigana);
                    setValue('postalcode', corporates[0].postCode);
                    setValue('state', corporates[0].prefectureName);
                    setValue('city', corporates[0].cityName);
                    setValue('street', corporates[0].streetNumber);
                    setValue('corporatenumberAccount', corporates[0].corporateNumber);
                  }
                }}
                renderInput={(params) => (
                  <Grid container>
                    <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                      <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                        {kindState == '法人' ? '会社名' : '屋号'}
                      </InputLabel>
                      <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                        ※株式会社など法人種別を含まない名称を入力してください
                      </InputLabel>
                    </Grid>
                    <Grid item md={9} sm={12} xs={12}>
                      <FormGroup row >
                        <TextFieldWText
                          {...field}
                          {...params}
                          ref={corpRef}
                          sx={{mt: {xs: 0, md: 2}}}
                          placeholder="入力してください"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          margin="normal"
                        />
                        <TextFieldWButton variant="contained" onClick={searchCompany} sx={{mt: {xs: 0, md: 2}}}>検索</TextFieldWButton>
                      </FormGroup>
                    </Grid>
                  </Grid>
                )}
              />
              :
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    屋号
                  </InputLabel>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                    ※屋号がない場合、代表者様の氏名をご入力ください
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 0, md: 2}}}
                    margin="normal"
                    color="secondary"
                    placeholder="入力してください"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                </Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name='companynameFurigana'
            rules={validationRules['companynameFurigana']}
            render={({ field, fieldState }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  {kindState == '法人' ? '会社名(フリガナ)' : '屋号(フリガナ)'}
                  </InputLabel>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                  {kindState == '法人' ? '※全角カナでご入力ください' : '※屋号がない場合、代表者様の氏名（フリガナ）を全角カナでご入力ください'}
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 0, md: 2}}}
                    margin="normal"
                    color="secondary"
                    placeholder="入力してください"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                </Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name='mobilephone'
            rules={validationRules['mobilephone']}
            render={({ field, fieldState }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    携帯番号
                  </InputLabel>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                    ※SMS受信可能な番号をご入力ください
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 0, md: 2}}}
                    margin="normal"
                    color="secondary"
                    placeholder="入力してください"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                </Grid>
              </Grid>
            )}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 2, md: 2}, mb: {xs: 1, md: 2}}}
        >
          次へ
        </Button>
      </form>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Box>
  );
}