import React from 'react';
import { Box, Button, Typography} from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export const DeleteComplete = () =>{
  const navigate = useNavigate();

  useEffect(() => {
  }, []);

  const onClickTop = () =>{
    navigate('/login')
  }

  return (
    <Box
      sx={{
        backgroundColor: '#F0EFEA',
        width: {md: '60%'},
        height: {md: '300px'},
        margin: '0 auto',
        borderRadius: '5px',
        minWidth: {md: '470px'},
        mt: {md: 10, xs: 2},
        textAlign: 'left',
        pr: 2,
        pl: 2,
        pt: 1,
        pb: 1
      }}
    >
      <Typography sx={{fontWeight: 'bold', fontSize: 17, pt: 5, textAlign: 'center', mb: 2}}>退会手続きが完了しました。</Typography>
      <Typography>ビートレーディングのファクタリングサービスをご利用いただきありがとうございました。</Typography>
      <Typography>またのご利用を心よりお待ちしております。</Typography>
      <Typography sx={{mt: 2}}>※退会のタイミングによりメールマガジン等が配信される場合がございます。ご了承ください。</Typography>
      <Box sx={{mt: 3, textAlign: 'center'}}>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>{onClickTop()}}
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          Topへ戻る
        </Button>
      </Box>
    </Box>
  );
}