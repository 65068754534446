import {client} from "./client"
import Cookies from 'js-cookie';

interface Draft{
  draft: string;
  user_id: string;
}

export const getDraftOpportunityByUserId = () => {
  return client.get(`/draft_opportunity/user_id?user_id=${Cookies.get('currentUserId')}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  })
}

export const getDraftOpportunityById = (draft_id: string) => {
  return client.get(`/draft_opportunity/${draft_id}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  })
}

export const createDraftOpportunity = (params: Draft) =>{
  const formData = new FormData();
  formData.append('draft_opportunity[draft]', params.draft);
  formData.append('draft_opportunity[portal_user_id]', params.user_id);
  return client.post('/draft_opportunity', formData, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const updateDraftOpportunity = (params: Draft, id: string) =>{
  const formData = new FormData();
  formData.append('draft_opportunity[draft]', params.draft);
  formData.append('draft_opportunity[portal_user_id]', params.user_id);
  return client.put(`/draft_opportunity/${id}`, formData, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const destroyDraftOpportunity = (id: string) =>{
  return client.delete(`/draft_opportunity/${id}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}