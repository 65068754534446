import {Divider, List, ListItem,ListItemButton,ListItemIcon,ListItemText,Box,Drawer, Typography, SvgIcon } from '@mui/material';
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
const drawerWidth = 200;

interface Props {
  window?: () => Window;
}

export default function Sidebar(props: Props){
  const { window } = props;
  const [currentPath, setCurrentPath] = useState('');
  const location = useLocation();
  const [contactInfo, setContactInfo] = useState({bankname: '', bankinfo: '', accountname: '', accountnamekana: '', phone: ''});
  const [isViewContact, setIsViewContact] = useState(false);
  const [isAdminViewContact, setIsAdminViewContact] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setCurrentPath(location.pathname);
    if (Cookies.get('isSignedIn') == 'true' && Cookies.get('isSMSVerified') == 'true'){
      const contact = JSON.parse(Cookies.get('_contact'))
      if(contact){
        setContactInfo(contact);
        setIsViewContact(true);
      }
    }else{
      setIsViewContact(false);
    }
    if(Cookies.get('_admin_access_token') && location.pathname.indexOf('/admin') >= 0){
      setIsAdmin(true);
      setIsAdminViewContact(true);
    }else{
      setIsAdmin(false);
      setIsAdminViewContact(false);
    }
  }, [location.pathname])

  const isIncludePath = (selectMenu) =>{
    if(selectMenu == '/profile'){
      if(currentPath.indexOf('profile') >= 0 || currentPath.indexOf('client') >= 0 || currentPath.indexOf('ekyc') >= 0) return true;
    }else if(selectMenu == '/factoring/top'){
      if(currentPath.indexOf('factoring') >= 0) return true;
      if(currentPath.indexOf('status') >= 0) return true;
    }else if(selectMenu == '/file_addition_list'){
      if(currentPath.indexOf('file_addition') >= 0) return true;
    }else if(selectMenu == '/faq'){
      if(currentPath.indexOf('faq') >= 0) return true;
    }else if(selectMenu == '/terms_of_service'){
      if(currentPath.indexOf('terms_of_service') >= 0) return true;
    }else if(selectMenu == '/'){
      if(currentPath == '/' || currentPath == '/top' || currentPath == '/payment_list') return true
    }else{
      return false
    }
  }

  const isAdminIncludePath = (selectMenu) =>{
    if(selectMenu == '/admin/profile'){
      if(currentPath.indexOf('profile') >= 0 || currentPath.indexOf('client') >= 0 || currentPath.indexOf('ekyc') >= 0) return true;
    }else if(selectMenu == '/admin/factoring/top'){
      if(currentPath.indexOf('factoring') >= 0) return true;
      if(currentPath.indexOf('status') >= 0) return true;
    }else if(selectMenu == '/admin/file_addition_list'){
      if(currentPath.indexOf('file_addition') >= 0) return true;
    }else if(selectMenu == '/admin/faq'){
      if(currentPath.indexOf('faq') >= 0) return true;
    }else if(selectMenu == '/admin/terms_of_service'){
      if(currentPath.indexOf('terms_of_service') >= 0) return true;
    }else if(selectMenu == '/admin/top'){
      if(currentPath == '/admin/top' || currentPath == '/admin/payment_list') return true
    }else{
      return false
    }
  }


  const drawer = (
    <div>
      {Cookies.get('isSignedIn') == 'true' && Cookies.get('isSMSVerified') == 'true' && !isAdmin ?
        <>
        <List sx={{mt: 3}}>
          {[
            {'ホーム': '/', 'img_on': '/icons/icon_on_home.svg', 'img_off': '/icons/icon_off_home.svg'},
            {'会員情報': '/profile', 'img_on': '/icons/icon_on_profile.svg', 'img_off': '/icons/icon_off_profile.svg'},
            {'審査の申し込み': '/factoring/top', 'img_on': '/icons/icon_on_factoring.svg', 'img_off': '/icons/icon_off_factoring.svg'},
            {'追加で書類提出': '/file_addition_list', 'img_on': '/icons/icon_on_document.svg', 'img_off': '/icons/icon_off_document.svg'}
          ].map((text, index) => (
            <ListItem key={Object.keys(text)[0]} button disablePadding component={Link} to={Object.values(text)[0]}>
              { isIncludePath(Object.values(text)[0]) ?
                <ListItemButton sx={{backgroundColor: "#FFFFFF", borderTopLeftRadius: 40, borderBottomLeftRadius: 40, ml: 1}}>
                  <img src={text['img_on']} alt="Icon"/>
                  <ListItemText primary={Object.keys(text)[0]} sx={{color: "#D06960", fontWeight: 'bold', ml: 1}}/> 
                </ListItemButton>
                :
                <ListItemButton sx={{ml: 1}}>
                  <img src={text['img_off']} alt="Icon"/>
                  <ListItemText primary={Object.keys(text)[0]} sx={{ml: 1}}/>
                </ListItemButton>
              }
            </ListItem>
          ))}
        </List>
        <Divider variant="middle" sx={{mb: 1, borderColor: "#D16960"}}/>
        {[
            {'よくある質問': '/faq', 'img_on': '/icons/icon_q.svg', 'img_off': '/icons/icon_q_2.svg'},
            {'利用規約': '/terms_of_service', 'img_on': '/icons/icon_on_termsofservice.svg', 'img_off': '/icons/icon_off_termsofservice.svg'},
          ].map((text, index) => (
            <ListItem key={Object.keys(text)[0]} button disablePadding component={Link} to={Object.values(text)[0]}>
              { isIncludePath(Object.values(text)[0]) ?
                <ListItemButton sx={{backgroundColor: "#FFFFFF", borderTopLeftRadius: 40, borderBottomLeftRadius: 40, ml: 1}}>
                  <img src={text['img_on']} alt="Icon"/>
                  <ListItemText primary={Object.keys(text)[0]} sx={{color: "#D06960", fontWeight: 'bold', ml: 1}}/> 
                </ListItemButton>
                :
                <ListItemButton sx={{ml: 1}}>
                  <img src={text['img_off']} alt="Icon"/>
                  <ListItemText primary={Object.keys(text)[0]} sx={{ml: 1}}/>
                </ListItemButton>
              }
            </ListItem>
          ))}
        </>
      :
        <></>
      }
    </div>
  );

  const adminDrawer = (
    <div>
      {isAdmin && location.pathname != '/admin'?
        <>
        <List sx={{mt: 3}}>
          {[
            {'ホーム': '/admin/top', 'img_on': '/icons/icon_on_home.svg', 'img_off': '/icons/icon_off_home.svg'},
            {'会員情報': '/admin/profile', 'img_on': '/icons/icon_on_profile.svg', 'img_off': '/icons/icon_off_profile.svg'},
            {'審査の申し込み': '/admin/factoring/top', 'img_on': '/icons/icon_on_factoring.svg', 'img_off': '/icons/icon_off_factoring.svg'},
            {'追加で書類提出': '/admin/file_addition_list', 'img_on': '/icons/icon_on_document.svg', 'img_off': '/icons/icon_off_document.svg'}
          ].map((text, index) => (
            <ListItem key={Object.keys(text)[0]} button disablePadding component={Link} to={Object.values(text)[0]}>
              { isAdminIncludePath(Object.values(text)[0]) ?
                <ListItemButton sx={{backgroundColor: "#FFFFFF", borderTopLeftRadius: 40, borderBottomLeftRadius: 40, ml: 1}}>
                  <img src={text['img_on']} alt="Icon"/>
                  <ListItemText primary={Object.keys(text)[0]} sx={{color: "#D06960", fontWeight: 'bold', ml: 1}}/> 
                </ListItemButton>
                :
                <ListItemButton sx={{ml: 1}}>
                  <img src={text['img_off']} alt="Icon"/>
                  <ListItemText primary={Object.keys(text)[0]} sx={{ml: 1}}/>
                </ListItemButton>
              }
            </ListItem>
          ))}
        </List>
        <Divider variant="middle" sx={{mb: 1, borderColor: "#D16960"}}/>
        {[
            {'よくある質問': '/admin/faq', 'img_on': '/icons/icon_q.svg', 'img_off': '/icons/icon_q_2.svg'},
            {'利用規約': '/admin/terms_of_service', 'img_on': '/icons/icon_on_termsofservice.svg', 'img_off': '/icons/icon_off_termsofservice.svg'},
          ].map((text, index) => (
            <ListItem key={Object.keys(text)[0]} button disablePadding component={Link} to={Object.values(text)[0]}>
              { isAdminIncludePath(Object.values(text)[0]) ?
                <ListItemButton sx={{backgroundColor: "#FFFFFF", borderTopLeftRadius: 40, borderBottomLeftRadius: 40, ml: 1}}>
                  <img src={text['img_on']} alt="Icon"/>
                  <ListItemText primary={Object.keys(text)[0]} sx={{color: "#D06960", fontWeight: 'bold', ml: 1}}/> 
                </ListItemButton>
                :
                <ListItemButton sx={{ml: 1}}>
                  <img src={text['img_off']} alt="Icon"/>
                  <ListItemText primary={Object.keys(text)[0]} sx={{ml: 1}}/>
                </ListItemButton>
              }
            </ListItem>
          ))}
        </>
      :
        <></>
      }
    </div>
  );

  const contactDrawer = (
    <Box>
      {!isAdmin && isViewContact ? 
      <Box sx={{backgroundColor: "#FFF", mt: 3, mr: 1, ml: 1, borderRadius: 2}}>
        {contactInfo.phone && contactInfo.phone != '-' ? 
        <List dense sx={{pb: 0}}>
          <ListItem sx={{pt: 0, pb: 0}}>
            <ListItemText primary={
              <>
                <Typography sx={{fontSize: '11px', fontWeight: 'bold'}}>
                  ＜連絡先＞
                </Typography>
                <Typography sx={{display: {xs: 'block', md: 'none'}, fontSize: '11px', fontWeight: 'bold'}}>
                  <Box component='a' sx={{wordBreak: 'break-all'}}  className='link'　target='_blank' href={`tel:${contactInfo.phone}`}>
                    {contactInfo.phone}
                  </Box>
                </Typography>
                <Typography sx={{display: {xs: 'none', md: 'block'}, fontSize: '11px', fontWeight: 'bold'}}>
                  {contactInfo.phone}
                </Typography>
              </>
            } />
          </ListItem>
        </List>
        :
        <></>
        }
        {contactInfo.bankname ?
        <List dense sx={{pt: 0}}>
          <ListItem sx={{pt: 0, pb: 0}}><ListItemText primary={
            <>
              <Typography sx={{fontSize: '11px', fontWeight: 'bold'}}>＜振込先口座＞</Typography>
              <Typography sx={{fontSize: '11px', fontWeight: 'bold'}}>{contactInfo.bankname}</Typography>
              <Typography sx={{fontSize: '11px', fontWeight: 'bold'}}>{contactInfo.bankinfo}</Typography>
              <Typography sx={{fontSize: '11px', fontWeight: 'bold'}}>{contactInfo.accountnamekana}</Typography>
            </>
          }/>
          </ListItem>
        </List>
        :
        <></>
        }
      </Box>
      :
      <></>}
    </Box>
  );

  const contactAdminDrawer = (
    <Box>
      {isAdminViewContact && isAdmin && location.pathname != '/admin'? 
      <Box sx={{backgroundColor: "#FFF", mt: 3, mr: 1, ml: 1, borderRadius: 2}}>
        {contactInfo.phone && contactInfo.phone != '-' ? 
        <List dense sx={{pb: 0}}>
          <ListItem sx={{pt: 0, pb: 0}}>
            <ListItemText primary={
              <>
                <Typography sx={{fontSize: '11px', fontWeight: 'bold'}}>
                  ＜連絡先＞
                </Typography>
                <Typography sx={{display: {xs: 'block', md: 'none'}, fontSize: '11px', fontWeight: 'bold'}}>
                  <Box component='a' sx={{wordBreak: 'break-all'}}  className='link'　target='_blank' href={`tel:${contactInfo.phone}`}>
                    {contactInfo.phone}
                  </Box>
                </Typography>
                <Typography sx={{display: {xs: 'none', md: 'block'}, fontSize: '11px', fontWeight: 'bold'}}>
                  {contactInfo.phone}
                </Typography>
              </>
            } />
          </ListItem>
        </List>
        :
        <></>
        }
        {contactInfo.bankname ?
        <List dense sx={{pt: 0}}>
          <ListItem sx={{pt: 0, pb: 0}}><ListItemText primary={
            <>
              <Typography sx={{fontSize: '11px', fontWeight: 'bold'}}>＜振込先口座＞</Typography>
              <Typography sx={{fontSize: '11px', fontWeight: 'bold'}}>{contactInfo.bankname}</Typography>
              <Typography sx={{fontSize: '11px', fontWeight: 'bold'}}>{contactInfo.bankinfo}</Typography>
              <Typography sx={{fontSize: '11px', fontWeight: 'bold'}}>{contactInfo.accountnamekana}</Typography>
            </>
          }/>
          </ListItem>
        </List>
        :
        <></>
        }
      </Box>
      :
      <></>}
    </Box>
  );
  return (
    <Box
      component="nav"
      sx={{
        width: { md: drawerWidth },
        height: 600,
        flexShrink: { md: 0 },
        display: { xs: 'none', md: 'block' },
        backgroundColor: isAdmin ? '#D19CFC' : '#FCD19C',
        position: 'fixed'
      }}
      aria-label="mailbox folders"
    >
      {drawer}
      {contactDrawer}
      {adminDrawer}
      {contactAdminDrawer}
    </Box>
  );
}