import React, { useCallback, useEffect, useRef, useState } from 'react';
import {Box, Divider, Typography, Link as MuiLink, Grid  } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { FaqContent } from '../components/FaqContent';
import { getProfile } from '../lib/api/profile';
import { Element, scroller} from 'react-scroll';
import { ErrorModal } from '../components/ErrorModal';
import { ConfirmModal } from '../components/ConfirmModal';
import { getDraftOpportunityByUserId } from '../lib/api/draftOpportunity';

interface Draft {
  draft: string;
  id: string;
}

export const Faq = () =>{
  const navigate = useNavigate();
  const [lastOppState, setLastOppState] = useState('');
  const [tradingCondition, setTradingCondition] = useState('');
  const refFactoring = useRef();
  const refKeiyaku = useRef();
  const refRiyou = useRef();
  const refSousa = useRef();
  const refHonnin = useRef();
  const parentRef = useRef();
  const [contactInfo, setContactInfo] = useState({bankname: '', bankinfo: '', accountname: '', accountnamekana: '', phone: ''});
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState<any>();
  const [draft, setDraft] = useState<Draft>({draft: '', id: ''});

  const handleGetProfile = async () =>{
    const res = await getProfile();
    const res2 = await getDraftOpportunityByUserId();
    if(res.status === 200){
      setLastOppState(res.data.record.lastOppStatus);
      setTradingCondition(res.data.record.tradingCondition);
    }
    if(res2.status === 200){
      setConfirmMessage(<><Typography>審査申し込みの下書きが残っていますがよろしいでしょうか？</Typography></>)
      setDraft(res2.data.record);
    }
  }

  useEffect(() => {
    const contact = JSON.parse(Cookies.get('_contact'));
    handleGetProfile();
    setContactInfo(contact);
  }, [])

  const linkDelete = () =>{
    if(lastOppState && lastOppState != '見込み' && lastOppState != 'NG' && lastOppState != 'キャンセル' && lastOppState != '実行済み'){
      setErrorMessage(<Typography>申請中は退会できません。</Typography>);
      handleOpenErrorModal();
      return;
    }
    if(tradingCondition == '契約中'){
      setErrorMessage(<Typography>ご契約中は退会できません。</Typography>);
      handleOpenErrorModal();
      return;
    }
    if(draft && draft.id){
      setConfirmModalOpen(true);
      return;
    }
    navigate(`/delete/${Cookies.get('currentUserId')}`)
  }

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const handleOkClick = () =>{
    setConfirmModalOpen(false);
    navigate(`/delete/${Cookies.get('currentUserId')}`);
  }

  const handleCancelClick = () =>{
    setConfirmModalOpen(false);
    return;
  }

  const handleCloseConfirmModal = () =>{
    setConfirmModalOpen(false);
    return;
  }

  const scroll = (elementName, ref) =>{
    const titleRect = ref.current.getBoundingClientRect();
    const margin = window.innerWidth <= 768 ? 62 : 0;
    const offset = - (titleRect.height * 1.3) - margin;
    scroller.scrollTo(elementName, {
      smooth: true,
      offset: offset
    })
  }

  const scrollFactoring = useCallback((evt)=>{
    scroll('factoringElement', refFactoring);
  }, []);
  const scrollKeiyaku = useCallback((evt)=>{
    scroll('keiyakuElement', refKeiyaku);
  }, []);
  const scrollHonnin = useCallback((evt)=>{
    scroll('honninElement', refHonnin);
  }, []);
  const scrollRiyou = useCallback((evt)=>{
    scroll('riyouElement', refRiyou);
  }, []);
  const scrollSousa = useCallback((evt)=>{
    scroll('sousaElement', refSousa);
  }, []);

  return (
    <Box
      ref={parentRef}
      sx={{
      backgroundColor: "#FFFFFF", 
      p: 2,
      borderRadius: 2
    }}>
      <Box sx={{
        display: {md: 'flex'}
      }}>
        <Box
          onClick={scrollFactoring}
          sx={[
            {
              border: '1px solid #000',
              width: {xs: '100%', md: "33%"},
              minHeight: {md: '100px', xs: '40px'},
              maxHeight: {md: '100px', xs: '40px'},
              borderRadius: {md: 5 , xs: 3},
              cursor: 'pointer',
              position: 'relative',
              mr: {md: 1},
              mt: {xs: 2},
              boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.15)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            },
            {'&:hover': {
              backgroundColor: '#F0F0F0'
            }}
          ]}
        >
          <Grid>
            <Typography sx={{fontWeight: 'bold', pt: 0}}>ファクタリングについて</Typography>
          </Grid>
          <Grid>
            <Box component="img" src="/icons/icon_arrow.svg" sx={{position: 'absolute', left: {md: 'calc((100% - 23px)/2)', xs: 'calc((100% - 40px))'}, top: {md: 60, xs: 10}, display: {md: 'none', xs: 'block'}}}/>
          </Grid>
        </Box>
        <Box
          onClick={scrollKeiyaku}
          sx={[
            {
              border: '1px solid #000',
              width: {xs: '100%', md: "33%"},
              minHeight: {md: '100px', xs: '40px'},
              maxHeight: {md: '100px', xs: '40px'},
              borderRadius: {md: 5 , xs: 3},
              cursor: 'pointer',
              position: 'relative',
              mr: {md: 1},
              mt: {xs: 2},
              boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.15)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            },
            {'&:hover': {
              backgroundColor: '#F0F0F0'
            }}
          ]}
        >
          <Grid>
            <Typography sx={{fontWeight: 'bold', pt: 0, display: {xs: 'block', md: 'none'}}}>お申し込み契約について</Typography>
            <Typography sx={{fontWeight: 'bold', pt: 0, display: {xs: 'none', md: 'block'}}}>お申し込み</Typography>
            <Typography sx={{fontWeight: 'bold', display: {xs: 'none', md: 'block'} }}>契約について</Typography>
          </Grid>
          <Grid>
            <Box component="img" src="/icons/icon_arrow.svg" sx={{position: 'absolute', left: {md: 'calc((100% - 23px)/2)', xs: 'calc((100% - 40px))'}, top: {md: 60, xs: 10}, display: {md: 'none', xs: 'block'}}}/>
          </Grid>
        </Box>
        <Box
          onClick={scrollRiyou}
          sx={[
            {
              border: '1px solid #000',
              width: {xs: '100%', md: "33%"},
              minHeight: {md: '100px', xs: '40px'},
              maxHeight: {md: '100px', xs: '40px'},
              borderRadius: {md: 5 , xs: 3},
              cursor: 'pointer',
              position: 'relative',
              mr: {md: 1},
              mt: {xs: 2},
              boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.15)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            },
            {'&:hover': {
              backgroundColor: '#F0F0F0'
            }}
          ]}
        >
          <Grid>
            <Typography sx={{fontWeight: 'bold', pt: 0}}>利用方法について</Typography>
          </Grid>
          <Grid>
            <Box component="img" src="/icons/icon_arrow.svg" sx={{position: 'absolute', left: {md: 'calc((100% - 23px)/2)', xs: 'calc((100% - 40px))'}, top: {md: 60, xs: 10}, display: {md: 'none', xs: 'block'}}}/>
          </Grid>
        </Box>
        <Box
          onClick={scrollHonnin}
          sx={[
            {
              border: '1px solid #000',
              width: {xs: '100%', md: "33%"},
              minHeight: {md: '100px', xs: '40px'},
              maxHeight: {md: '100px', xs: '40px'},
              borderRadius: {md: 5 , xs: 3},
              cursor: 'pointer',
              position: 'relative',
              mr: {md: 1},
              mt: {xs: 2},
              boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.15)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            },
            {'&:hover': {
              backgroundColor: '#F0F0F0'
            }}
          ]}
        >
          <Grid>
            <Typography sx={{fontWeight: 'bold', pt: 0, display: {xs: 'block', md: 'none'}}}>かんたん本人確認について</Typography>
            <Typography sx={{fontWeight: 'bold', pt: 0, display: {xs: 'none', md: 'block'}}}>かんたん</Typography>
            <Typography sx={{fontWeight: 'bold', display: {xs: 'none', md: 'block'}}}>本人確認について</Typography>
          </Grid>
          <Grid>
            <Box component="img" src="/icons/icon_arrow.svg" sx={{position: 'absolute', left: {md: 'calc((100% - 23px)/2)', xs: 'calc((100% - 40px))'}, top: {md: 60, xs: 10}, display: {md: 'none', xs: 'block'}}}/>
          </Grid>
        </Box>
        <Box
          onClick={scrollSousa}
          sx={[
            {
              border: '1px solid #000',
              width: {xs: '100%', md: "33%"},
              minHeight: {md: '100px', xs: '40px'},
              maxHeight: {md: '100px', xs: '40px'},
              borderRadius: {md: 5 , xs: 3},
              cursor: 'pointer',
              position: 'relative',
              mr: {md: 1},
              mt: {xs: 2},
              boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.15)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            },
            {'&:hover': {
              backgroundColor: '#F0F0F0'
            }}
          ]}
        >
          <Grid>
            <Typography sx={{fontWeight: 'bold', pt: 0}}>操作方法について</Typography>
          </Grid>
          <Grid>
            <Box component="img" src="/icons/icon_arrow.svg" sx={{position: 'absolute', left: {md: 'calc((100% - 23px)/2)', xs: 'calc((100% - 40px))'}, top: {md: 60, xs: 10}, display: {md: 'none', xs: 'block'}}}/>
          </Grid>
        </Box>
      </Box>
      <Box sx={{mb: 2, mt: 4}} ref={refFactoring}>
        <Element name="factoringElement"></Element>
        <Typography sx={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}} id="factoring">
        ファクタリングについて
        </Typography>
        <Box component='span' sx={{
          background: 'linear-gradient(to right, #F5D3A3, #D16960)',
          width: '70px',
          height: '3px',
          display: 'inline-block',
        }} />
      </Box>
      <Divider />
      <FaqContent
        subject="ファクタリングとは何ですか？"
        messages={[
          <Typography>ファクタリングとはお客様がお持ちの売掛金(売掛債権)をファクタリング会社に売却することで売掛先からの入金日よりも前に売掛金を資金化する金融サービスです。</Typography>,
          <Typography>ファクタリングについて詳しくは<Box component='a' sx={{wordBreak: 'break-all'}}  className='link'　target='_blank' href='https://betrading.jp/contents/factoring-basic/factoring/'>こちら</Box></Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="どのような売掛債権をファクタリングしてもらえますか？"
        messages={[
          <Typography>売掛先が法人の売掛債権であれば買取可能です。</Typography>,
          <Typography>ただし、回収が遅れている不良債権や給与債権でのファクタリング契約はできませんのでご注意ください。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="利用できない業種などはありますか？"
        messages={[
          <Typography>売掛先が法人であればどのような業種でもご利用いただけます。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="個人事業主でも利用できますか？"
        messages={[
          <Typography>売掛先が法人であれば、個人事業主様でもご利用いただけます。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="いくらの請求書（売掛金）から買取可能ですか？"
        messages={[
          <Typography>ビートレーディングでは買取金額に下限・上限はございません。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="分割払いはできますか？"
        messages={[
          <Typography>ファクタリングは売掛金(売掛債権)の売買ですので、売掛先から入金された日に一括でお振込みいただいております。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="給与ファクタリングはできますか？"
        messages={[
          <Typography>給与ファクタリングとは入金予定の給与を給与債権として譲渡し、入金日よりも前に資金化するサービスです。</Typography>,
          <Typography>給与ファクタリングは貸金業にあたるため当社での取り扱いはございません。</Typography>
        ]}
      />
      <Divider />
      <Box sx={{mb: 2, mt: 3}} ref={refKeiyaku}>
        <Element name="keiyakuElement"></Element>
        <Typography sx={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}}>
        お申し込み・契約について
        </Typography>
        <Box component='span' sx={{
          background: 'linear-gradient(to right, #F5D3A3, #D16960)',
          width: '70px',
          height: '3px',
          display: 'inline-block',
        }} />
      </Box>
      <Divider/>
      <FaqContent
        subject="申し込みから入金までどれくらいかかりますか？"
        messages={[
          <Typography>最短2時間で入金が可能です。事前に必要書類をご用意いただけますとスムーズに審査が可能です。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="申し込みに必要な書類は何ですか？"
        messages={[
          <Typography>債権に関する書類（請求書、注文書等）、通帳のコピー（2か月分）の2点となります。</Typography>,
          <Typography>債権の内容によっては追加の書類をお願いする場合もございますので予めご了承ください。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="契約に必要な書類は何ですか？"
        messages={[
          <Typography>オンライン契約では原則契約に必要な書類はございませんが、買取金額によっては書類の提出をお願いする場合がございます。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="審査結果はどのように確認したらいいですか？"
        messages={[
          <Typography>会員ページ内の「<MuiLink sx={{wordBreak: 'break-all'}} component={Link} to={`/status_list`} underline="hover">申請履歴</MuiLink>」をご確認ください。</Typography>,
          <Typography>審査通過時には、ご登録のメールアドレス宛にクラウドサインから契約メールを送信いたします。</Typography>,
          <Typography> ※「@cloudsign.jp」のドメインが迷惑メールに設定されていないかをご確認ください。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="審査結果が否決でした。理由を教えてください。"
        messages={[
          <Typography>大変申し訳ございません。審査結果の詳細についてはお答えできかねますので何卒ご了承ください。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="本人確認書類は何が必要ですか？"
        messages={[
          <Typography>運転免許証／マイナンバーカード／在留カード／住民基本台帳カード／運転経歴証明書／特別永住者証明書／パスポート／健康保険証などの身分証明書にて本人確認を行います。</Typography>,
          <Typography>※パスポート・健康保険証の場合は住所の確認できる補足書類の提出も必要です。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="契約後に売掛先が倒産した場合はどうなりますか？"
        messages={[
          <Typography>ビートレーディングのファクタリングは、売掛先の倒産リスクも含めて債権を買い取る償還請求権のない（ノンリコース）契約です。</Typography>,
          <Typography>万が一、売掛金(売掛債権)が未回収の状態で売掛先が倒産したとしても、お客様に対して支払いを求めることはございません。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="契約時に振込んでもらう口座の指定はできますか？"
        messages={[
          <Typography>会員情報を登録する際に、ご希望の銀行口座をご登録ください。</Typography>,
          <Typography>※口座情報に誤りがあるとお振込みまでに時間がかかりますのでご注意ください</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="契約後、振込みではなく現金でもらうことはできますか？"
        messages={[
          <Typography>原則お振込みでの対応となります。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="売掛先から入金がない・入金が遅れる旨の連絡があった場合どうすれば良いですか？"
        messages={[
          <Typography>売掛先からの入金がない・入金が遅れることがわかった時点で、担当者まで至急ご連絡ください。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="売掛先からの入金が早まりました。ビートレーディングへの支払いはどうすればいいですか？"
        messages={[
          <Typography>予定よりも早く売掛先から入金があった場合は、必ず担当者までご連絡をお願いいたします。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="ビートレーディングへ支払い時の振込手数料について教えてください。"
        messages={[
          <Typography>恐れ入りますが、ビートレーディングへのお支払い時に発生するお振込手数料は、お客様ご負担となります。ご了承ください。</Typography>
        ]}
      />
      <Divider />
      <Box sx={{mb: 2, mt: 3}} ref={refRiyou}>
        <Element name="riyouElement"></Element>
        <Typography sx={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}}>
        利用方法について
        </Typography>
        <Box component='span' sx={{
          background: 'linear-gradient(to right, #F5D3A3, #D16960)',
          width: '70px',
          height: '3px',
          display: 'inline-block',
        }} />
      </Box>
      <Divider/>
      <FaqContent
        subject="複数ファクタリング申請することは可能ですか？"
        messages={[
          <Typography>1度の申請で複数の売掛債権を申請することは可能ですが、1度ファクタリング申請を行った場合、審査結果が出るまで追加でファクタリング申請することはできません。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="請求書（売掛金）の金額の一部のみを申請することは可能ですか？"
        messages={[
          <Typography>可能です。</Typography>,
          <Typography>申請時に「調達希望金額」をご入力ください。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="一部誤った内容でファクタリング申請をしてしまいました。どうすればいいですか？"
        messages={[
          <Typography>お手数ですが、1度申請をキャンセルしていただき再申請をお願いいたします。</Typography>,
          <Typography>内容の一部のみの修正でしたら「コピーして申請」からの申請をおすすめしております。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="ファクタリング申請をキャンセルしたいです。"
        messages={[
          <Typography>お手数ですが、「<MuiLink sx={{wordBreak: 'break-all'}} component={Link} to={`/status_list`} underline="hover">申請履歴</MuiLink>」よりキャンセルしたい申請の詳細ページに入り、「申請キャンセル」ボタンを押してキャンセルを行ってください。</Typography>
        ]}
      />
      <Divider />
      <FaqContent
        subject="売掛先の登録とは何ですか？"
        messages={[
          <Typography>売掛先を登録しておくことで次回申請時に売掛先情報の入力を省略して進むことができます。</Typography>,
          <Typography>スムーズな申請が可能なので登録をおすすめしております。</Typography>,
          <Typography>また、登録しても売掛先に利用を知られることはありませんのでご安心ください。</Typography>
        ]}
      />
      <Divider />
      { contactInfo?.bankname && contactInfo?.bankinfo && contactInfo?.accountnamekana ?
        <>
          <FaqContent
            subject="ビートレーディングへの振込先を教えてください。"
            messages={[
              <Typography>契約内容に従い以下口座にお振込みください。</Typography>,
              <Typography>＜振込先口座＞</Typography>,
              <Typography>{contactInfo.bankname}</Typography>,
              <Typography>{contactInfo.bankinfo}</Typography>,
              <Typography>{contactInfo.accountnamekana}</Typography>,
            ]}
          />
        </>
        :
        <FaqContent
          subject="ビートレーディングへの振込先を教えてください。"
          messages={[
            <Typography>振込先は契約後にマイページのトップよりご確認いただけます。</Typography>
          ]}
        />
      }
      <Divider/>
      <FaqContent
        subject="3者間契約はできますか？"
        messages={[
          <Typography>3者間契約をご希望の場合は下記ページより無料見積依頼してください。</Typography>,
          <Typography>無料見積依頼は<Box component='a' sx={{wordBreak: 'break-all'}} className='link' target='_blank' href='https://betrading.jp/estimate/'>こちら</Box></Typography>
        ]}
      />
      <Divider/>
      <Box sx={{mb: 2, mt: 3}} ref={refHonnin}>
        <Element name="honninElement"></Element>
        <Typography sx={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}}>
        かんたん本人確認について
        </Typography>
        <Box component='span' sx={{
          background: 'linear-gradient(to right, #F5D3A3, #D16960)',
          width: '70px',
          height: '3px',
          display: 'inline-block',
        }} />
      </Box>
      <Divider/>
      <FaqContent
        subject="本人確認は必須ですか？"
        messages={[
          <Typography>審査のお申込みにあたり、かんたん本人確認（代表者様の身分証）のご提出は必須としております。</Typography>,
        ]}
      />
      <Divider/>
      <FaqContent
        subject="かんたん本人確認が否認されました。理由を教えてください。"
        messages={[
          <Typography>本人確認ができなかった場合、ご登録のメールアドレスに否認理由をお送りしております。</Typography>,
          <Typography>お手数ですが、ご確認のうえ再度本人確認を実施してください。</Typography>,
          <Typography>身分証やセルフィー撮影のポイントについて詳しくは<Box component='a' sx={{wordBreak: 'break-all'}} className='link' target='_blank' href='https://biz.trustdock.io/verificationguide/app/ekyc-e'>こちら</Box></Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="セルフィーで否認されました。どのような写真を提出すれば良いですか？"
        messages={[
          <Typography>以下のような否認理由の場合、証明写真などその場で撮っていない写真の可能性があります。</Typography>,
          <Typography>セルフィーは、その場で撮影した顔写真をご提出いただく必要がありますので、証明写真などを提出していないかご確認ください。</Typography>,
          <Typography>・サングラス・帽子などで顔が隠れている</Typography>,
          <Typography>・顔がない</Typography>,
          <Typography>・その他</Typography>,
          <Typography>・セルフィーと公的身分証の顔写真が一致しない</Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="その場で撮ったセルフィー写真を送ったが否認されました。"
        messages={[
          <Typography>その場で撮った写真であっても以下のような場合は否認となりますので、顔が鮮明に写っているか、切り抜きなどの加工をしていないかご確認ください。</Typography>,
          <Typography>・顔がサングラスや帽子で隠れている</Typography>,
          <Typography>・写真が不鮮明で顔が見えない</Typography>,
          <Typography>・背景に別の人が写っている</Typography>,
          <Typography>・写真の加工を行っている（写真の切り抜きなど）</Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="かんたん本人確認の申請は何回もできますか？"
        messages={[
          <Typography>本人確認が複数回連続で否認されると本人確認を申請できなくなります。</Typography>,
          <Typography>万が一、申請できなくなった場合はお手数ですがオペレーターまでご相談ください。</Typography>
        ]}
      />
      <Divider/>
      <Box sx={{mb: 2, mt: 3}} ref={refSousa}>
        <Element name="sousaElement"></Element>
        <Typography sx={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}}>
        操作方法について
        </Typography>
        <Box component='span' sx={{
          background: 'linear-gradient(to right, #F5D3A3, #D16960)',
          width: '70px',
          height: '3px',
          display: 'inline-block',
        }} />
      </Box>
      <Divider/>
      <FaqContent
        subject="ログインパスワードを変更したいです。"
        messages={[
          <Typography>パスワード再設定ページより、再設定を行ってください。</Typography>,
          <Typography>パスワード再設定URLは<MuiLink sx={{wordBreak: 'break-all'}} target='_blank' component={Link} to={`/login/password_resetting`} underline="hover">こちら</MuiLink></Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="登録後のメールアドレスが届かず認証できません。"
        messages={[
          <Typography>お客様の迷惑メール対策設定状況によっては、メールが届かない場合があります。</Typography>,
          <Typography>「@portal.betrading.jp」のドメインが迷惑メールに設定されていないかをご確認ください。</Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="SMS認証が届きません。"
        messages={[
          <Typography>迷惑メールのフィルター設定でSMSの受信拒否設定などがされていないかをご確認ください。</Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="退会したいです。"
        messages={[
          <Typography>退会をご希望の場合は、退会ページよりお手続きをお願いいたします。</Typography>,
          <Typography>退会のお手続きは<MuiLink sx={{wordBreak: 'break-all'}} onClick={linkDelete} underline="hover">こちら</MuiLink></Typography>
        ]}
      />
      <Divider/>
      <FaqContent
        subject="このページで解決できませんでした。"
        messages={[
          <Typography sx={{display: {xs: 'block', md: 'none'}}}>お手数ですが、解決しない場合やお急ぎのお問い合わせは
            {contactInfo?.phone && contactInfo?.phone != '-' ? 
              <Box component='a' sx={{wordBreak: 'break-all'}}  className='link'　target='_blank' href={'tel:' + contactInfo.phone}>{contactInfo.phone}</Box>
            :
              <Box component='a' sx={{wordBreak: 'break-all'}}  className='link'　target='_blank' href='tel:0120-497-029'>0120-497-029</Box>
            }
          までお電話ください。</Typography>,
          <Typography sx={{display: {xs: 'none', md: 'block'}}}>お手数ですが、解決しない場合やお急ぎのお問い合わせは
            {contactInfo?.phone && contactInfo?.phone != '-' ? 
              `${contactInfo.phone}`
              :
              '0120-497-029'
            }
          までお電話ください。</Typography>,
          <Typography>対応時間は、平日9：30～18：00 です。</Typography>
        ]}
      />
      <Divider/>
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
      <ConfirmModal message={confirmMessage} modalOpen={confirmModalOpen} cancelClick={handleCancelClick} okClick={handleOkClick} closeClick={handleCloseConfirmModal}></ConfirmModal>
    </Box>
  );
};