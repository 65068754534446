import React, { useState } from 'react';
import { Collapse, Typography, Link, Box, Divider, Grid } from '@mui/material';

export const FaqContent = (props) =>{
  const [visible, setVisible] = useState(false);
  const subject = props.subject;
  const messages = props.messages;

  const subjectClickHandler = () =>{
    setVisible(!visible);
  }

  return (
    <>
      <Grid container onClick={subjectClickHandler}>
        <Grid item xs={11}>
          <Typography 
            className='betrading_message_content'
            sx={{
              marginTop: 3,
              marginBottom: 3,
            }}
          >
            <Box component='img' src="/icons/icon_q.svg"/>
            {subject}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography sx={{
              marginTop: 3,
              fontWeight: 'bold',
              color: '#A7A597',
              fontSize: 28
          }}>{visible ? 'ー' : '＋'}</Typography>
        </Grid>
      </Grid>
      <Divider sx={{display: visible ? 'block' : 'none'}} />
      <Collapse in={visible}>
        <Box sx={{position: 'absolute', paddingTop: 2.5}}><Box component='img' src="/icons/icon_a.svg"/></Box>
        {
          messages.map((message, idx) =>{
            return (
              <Box component='div' className='betrading_message_content' key={`message_${idx}`}
              sx={{
                marginLeft: 3,
                marginTop: idx==0 ? 3 : 1,
                fontSize: 14.5
              }}>
                {message}
              </Box>
            )
          })
        }
        <Box component='div' sx={{marginBottom: 3}}></Box>
      </Collapse>
    </>
  );
};