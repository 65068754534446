import React, { useRef, useState } from 'react';
import { Button, TextField, InputLabel, Select, MenuItem, FormControl, Hidden, FormHelperText, Grid, Autocomplete, Box, Typography, FormGroup } from '@mui/material';
import { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Input, defaultValues, AccountsReceivableFormInfo, validationRules } from '../../common/accounts_receivable';
import { getAddress } from "../../lib/api/postalCode"
import Loading from "../../components/Loading";
import { Corporate, TextFieldWButton, TextFieldWText } from '../../common/profile';
import { toFullWidth, toFullWidthKana } from '../../common/common';
import { ErrorModal } from '../../components/ErrorModal';
import { Element, scroller} from 'react-scroll';
import { sendErrorMail } from '../../lib/api/error_mail';
import { getAccountsReceivables, createAccountsReceivable, getCorporates } from '../../lib/api/guest';

export const GuestSalesToEdit = () =>{
  const { control, handleSubmit, setValue, getValues} = useForm<Input>({defaultValues});
  const navigate = useNavigate();
  const [ dateErrors, setDateErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [corporates, setCorporates] = useState<Corporate[]>([]);
  const corpRef = useRef<HTMLInputElement>(null);
  const corpAcRef = useRef<HTMLInputElement>(null);
  const [kindState, setKind] = useState('法人');
  const [acValue, setAcValue] = useState('');
  const [isApiButton, setIsApiButton] = useState(false);
  const [accReceivables, setAccReceivables] = useState<Input[]>([]);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const accId = query.get('Id');
  const accName = query.get('Name');
  const [isParamsError, setIsParamsError] = useState(false);

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const handleAccReceivables = async () =>{
    const res = await getAccountsReceivables(accId);
    if(res.status === 200){
      setAccReceivables(res.data.records.map(rec =>{
        return {
          kind: rec.type ? rec.type : '',
          companyName: rec.companyname ? rec.companyname : '',
          state: rec.state ? rec.state : '',
          city: rec.city ? rec.city : '',
          street: rec.street ? rec.street: '',
          other: rec.other ? rec.other : '',
          postalcode: rec.postalcode ? rec.postalcode : '',
          hpAddress: rec.hp ? rec.hp : '',
          phone: rec.telno ? rec.telno : '',
          fixDate: rec.closingdate ? rec.closingdate : '',
          paymentDate: rec.paymentdate ? rec.paymentdate : '',
          manager: rec.personincharge ? rec.personincharge : '',
          houjinNo: rec.corporationnumber ? rec.corporationnumber : ''
        }
      }));
    }
  }


  useEffect(() => {
    if(!accId){
      setIsParamsError(true);
    }
    handleAccReceivables();
  }, [])


  const onSubmit: SubmitHandler<Input> = async (evt) =>{
    
    const exist = kindState == '法人' ? accReceivables.find((ar) => {
      return (
        ar.companyName.replace(/\s+/g,'') == evt.companyName.replace(/\s+/g,'') &&
        ar.paymentDate == evt.paymentDate &&
        ar.fixDate == evt.fixDate &&
        ar.kind == evt.kind &&
        ar.houjinNo == evt.houjinNo
      );
    }) : accReceivables.find((ar) =>{
      return (
        ar.companyName.replace(/\s+/g,'') == evt.companyName.replace(/\s+/g,'') &&
        ar.phone == evt.phone &&
        ar.kind == evt.kind &&
        ar.paymentDate == evt.paymentDate &&
        ar.fixDate == evt.fixDate
      );
    })
    if(exist){
      setErrorMessage(<Typography>登録済みの売掛先です。</Typography>);
      handleOpenErrorModal();
      return;
    }
    const dateKeys = Object.keys(dateErrors);
    if(dateKeys.length > 0){
      let isError = false;
      dateKeys.forEach((k) =>{
        if(dateErrors[k].length > 0) isError = true;
      })
      if(isError) return;
    }
    if(isLoading) return;
    setIsLoading(true);
    try{
      const res = await createAccountsReceivable(evt, accId);
      setIsLoading(false);
      if(res.status === 200){
        navigate("/sold_to/completed/" + res.data.record.id + "?Id=" + accId + "&Name=" + accName);
      }
    }catch(e){
      setIsLoading(false);
      setErrorMessage(<Typography>{e.response.data.errors[0]}</Typography>);
      handleOpenErrorModal();
    }
  }

  const onError = (evt) =>{
    const fields = [
      'kind', 'companyName', 'postalcode', 'state', 'city', 'street',
      'other', 'houjinNo', 'hpAddress', 'phone', 'manager', 'fixDate', 'paymentDate'
    ]
    const keys = Object.keys(evt);
    let targetKey = '';
    for(let i = 0; i < fields.length; i++){
      if(keys.find(k => k == fields[i])){
        targetKey = fields[i];
        break;
      }
    }
    const offset = window.innerWidth <= 768 ? -162 : -75;
    scroller.scrollTo(targetKey, {
      smooth: true,
      offset: offset
    })
  };

  const handleAddressSearch = async (fid, value) =>{
    if(isApiButton) return;
    setIsApiButton(true);
    const res = await getAddress(value);
    const stateStr = 'state';
    const cityStr = 'city';
    const streetStr = 'street';
    if(res.status == 200 && res.data.results){
      // 1件取得できた時だけ値を更新する
      const address = res.data.results[0];
      setValue(stateStr, address.address1);
      setValue(cityStr, address.address2);
      setValue(streetStr, address.address3);
    }else{
      if(res.status == 500){
        await sendErrorMail(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${value}`, JSON.stringify(res.data), 'ZIP Cloud API');
      }
      setErrorMessage(<Typography>郵便番号を再入力してください。</Typography>);
      handleOpenErrorModal();
    }
    setIsApiButton(false);
  }

  const searchCompany = async() =>{
    if(isApiButton) return;
    setIsApiButton(true);
    const values = getValues();
    try{
      const ret = await getCorporates({name: values.companyName});
      const count = parseInt(ret.data.records.corporations.count);
      if(count > 1){
        setCorporates(ret.data.records.corporations.corporation);
      }else if(count == 1){
        setCorporates([ret.data.records.corporations.corporation]);
      }else{
        setCorporates([{
          name: '',
          corporateNumber: '',
          furigana: '',
          postCode: '',
          prefectureName: '',
          cityName: '',
          streetNumber: ''
        }])
        setErrorMessage(<Typography>検索候補がありません、入力内容を再度ご確認ください。</Typography>);
        handleOpenErrorModal();
      }
      setAcValue(values.companyName);
      setIsApiButton(false);
    }catch(e){
      setErrorMessage(<Typography>{e.response.data.message}</Typography>);
      handleOpenErrorModal();
      setIsApiButton(false);
    }
  }
  return (
    <Box sx={{pr: {xs: 1, md: 2}, pl: {xs: 1, md: 2}, pb: 2}}>
      {isParamsError ?
        <p>存在しないページです</p>
        :
        <form id="user-edit-form" onSubmit={handleSubmit(onSubmit, onError)}>
          {AccountsReceivableFormInfo.map((group, index) =>(
            <Box key={`stm_${index}`}>
            <Box sx={{
              borderLeft: "5px solid #D16960",
              textAlign: 'left',
              mt:2
            }}>
              <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>{group.title}</Typography>
              <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>{group.message}</Typography>
              <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}>{group.warning}</Typography>
            </Box>
            <Box
              key={`stb_${index}`}
              sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
            >
              { 
                group.forms.map((pform, idx) =>(
                  pform.fields.length > 1 && pform.id == 'horizontal' ?
                    <Grid container key={`pf_g_${idx}`}>
                      <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                        <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                          {pform.label}
                        </InputLabel>
                      </Grid>
                      {pform.fields.map((f) =>(
                        <Controller
                          key={f.id}
                          control={control}
                          name={f.id}
                          rules={validationRules[f.id]}
                          render={({ field, fieldState }) => (
                            <Grid item md={4.5} sm={6} xs={6}>
                              <Element name={f.id}></Element>
                              <TextField
                                {...field}
                                fullWidth
                                disabled
                                sx={{mt: {xs: 1, md: 2}}}
                                margin="normal"
                                color="secondary"
                                placeholder={f.placeholder}
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                              />
                            </Grid>
                          )}
                        />
                      ))}
                    </Grid>
                  : pform.fields.length > 1 && pform.id == 'vertical'?
                    <Box sx={{mb: {xs: 1}}} key={`pf_b_${idx}`}>
                      {pform.fields.map((f, i) =>(
                        <Grid container key={`pf_b_${idx}_${i}`}>
                          <Grid item md={3} sm={12} xs={12}sx={{m: 'auto'}}>
                            <InputLabel required={i==0 ? pform.required : false} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                              {i == 0 ? pform.label : ''}
                            </InputLabel>
                          </Grid>
                          <Controller
                            key={f.id}
                            control={control}
                            name={f.id}
                            rules={validationRules[f.id]}
                            render={({ field, fieldState }) => (
                              <>
                                {f.id == 'postalcode' ?
                                <Grid item md={9} sm={12} xs={12}>
                                  <Element name={f.id}></Element>
                                  <FormGroup row>
                                    <TextFieldWText
                                      {...field}
                                      fullWidth
                                      sx={{mt: {xs: i == 0 ? 1 : 0, md: 1, sm: 1}}}
                                      color="secondary"
                                      placeholder={f.placeholder}
                                      error={fieldState.invalid}
                                      helperText={fieldState.error?.message}
                                    />
                                    <TextFieldWButton disabled={isApiButton} sx={{mt: {xs: 1, md: 1, sm: 1}}} variant="contained" onClick={(e) =>{handleAddressSearch(f.id, field.value)}}>住所検索</TextFieldWButton>
                                  </FormGroup>
                                </Grid>
                                : f.id == 'paymentDate' || f.id == 'fixDate' ?
                                <>
                                <Grid item md={4.5} sm={12} xs={12}>
                                  <Element name={f.id}></Element>
                                  <TextField
                                    {...field}
                                    fullWidth
                                    sx={{mt: {xs: i == 0 ? 1 : 0, md: 1, sm: 1}}}
                                    color="secondary"
                                    placeholder={f.placeholder}
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message}
                                  />
                                </Grid>
                                <Grid item md={4.5} sm={0} xs={0}></Grid>
                                </>
                                :
                                <Grid item md={9} sm={12} xs={12}>
                                  <Element name={f.id}></Element>
                                  <TextField
                                    {...field}
                                    onBlur={(e) => {
                                      let value = e.target.value;
                                      if(value){
                                        value = toFullWidth(value);
                                        value = toFullWidthKana(value);
                                        setValue(f.id, value);
                                      }
                                    }}
                                    fullWidth
                                    sx={{mt: {xs: i == 0 ? 1 : 0, md: 1, sm: 1}}}
                                    color="secondary"
                                    placeholder={f.placeholder}
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message}
                                  />
                                </Grid>
                                }
                              </>
                            )}
                          />
                        </Grid>
                      ))}
                    </Box>
                  : pform.fields.length == 1 && (pform.fields[0].type == 'string'?
                    <Controller
                      key={pform.fields[0].id}
                      control={control}
                      name={pform.fields[0].id}
                      rules={validationRules[pform.fields[0].id]}
                      render={({ field, fieldState }) => (
                        pform.fields[0].id == 'companyName' && (!kindState || kindState == '法人') ?
                        <Autocomplete
                          freeSolo
                          ref={corpAcRef}
                          options={corporates}
                          getOptionLabel={(option: any) => option.name || ''}
                          renderOption={(props, option) => (
                            <Box key={option?.corporateNumber} >
                              <Typography {...props}>
                                {option?.name} ({option?.prefectureName}{option?.cityName}{option?.streetNumber})
                              </Typography>
                            </Box>
                          )}
                          value={corporates.length == 1 ? corporates[0] : ''}
                          onChange={(_event, value: any | null) => {
                            if(value){
                              setValue('companyName', value?.name);
                              setValue('postalcode', value?.postCode);
                              setValue('state', value?.prefectureName);
                              setValue('city', value?.cityName);
                              setValue('street', value?.streetNumber);
                              setValue('houjinNo', value?.corporateNumber);
                            }
                          }}
                          onInputChange={(_event, value, reason) =>{
                            if(reason == 'clear' || reason == 'input'){
                              setValue('companyName', '');
                              setValue('postalcode', '');
                              setValue('state', '');
                              setValue('city', '');
                              setValue('street', '');
                              setValue('houjinNo', '');
                            }
                            if(reason == 'reset' && value && corporates.length == 1){
                              setValue('companyName', corporates[0].name);
                              setValue('postalcode', corporates[0].postCode);
                              setValue('state', corporates[0].prefectureName);
                              setValue('city', corporates[0].cityName);
                              setValue('street', corporates[0].streetNumber);
                              setValue('houjinNo', corporates[0].corporateNumber);
                            }
                          }}
                          renderInput={(params) => (
                            <Grid container>
                              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                                <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                                  {pform.label}
                                </InputLabel>
                                <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                                  ※株式会社など法人種別を含まない名称を入力してください
                                </InputLabel>
                              </Grid>
                              <Grid item md={9} sm={12} xs={12}>
                                <Element name={pform.fields[0].id}></Element>
                                <FormGroup row >
                                  <TextFieldWText
                                    {...field}
                                    {...params}
                                    ref={corpRef}
                                    disabled={pform.fields[0].disable}
                                    sx={{mt: {xs: 1, md: 2}}}
                                    placeholder={pform.fields[0].placeholder}
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message}
                                    margin="normal"
                                  />
                                  <TextFieldWButton disabled={isApiButton} sx={{mt: {xs: 1, md: 2}}} variant="contained" onClick={searchCompany}>検索</TextFieldWButton>
                                </FormGroup>
                              </Grid>
                            </Grid>
                          )}
                        />
                        :
                        <Grid container sx={{display: (pform.fields[0].id == 'houjinNo' && kindState == '個人事業主') ? 'none' : 'flex'}}>
                          <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                            <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                              {pform.label}
                            </InputLabel>
                            { pform.fields[0].id == 'houjinNo' ?
                              <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                                ※会社名を検索すると自動的に入力されます
                              </InputLabel>
                              :
                              <></>
                            }
                          </Grid>
                          { pform.fields[0].id == 'phone' || pform.fields[0].id == 'manager' ?
                          <>
                          <Grid item md={4.5} sm={6} xs={6}>
                            <Element name={pform.fields[0].id}></Element>
                            <TextField
                              {...field}
                              fullWidth
                              sx={{mt: {xs: 1, md: 2}}}
                              margin="normal"
                              color="secondary"
                              disabled={pform.fields[0].disable}
                              placeholder={pform.fields[0].placeholder}
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          </Grid>
                          <Grid item md={4.5} sm={6} xs={6}></Grid>
                          </>
                          :
                          <Grid item md={9} sm={12} xs={12}>
                            <Element name={pform.fields[0].id}></Element>
                            <TextField
                              {...field}
                              fullWidth
                              sx={{mt: {xs: 1, md: 2}}}
                              margin="normal"
                              color="secondary"
                              disabled={pform.fields[0].disable}
                              placeholder={pform.fields[0].placeholder}
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          </Grid>
                          }
                        </Grid>
                      )}
                    />
                  : pform.fields.length == 1 && (pform.fields[0].type == 'select') ?
                    <Controller
                      key={pform.fields[0].id}
                      control={control}
                      name={pform.fields[0].id}
                      rules={validationRules[pform.fields[0].id]}
                      render={({ field, fieldState }) => (
                        <Grid container>
                          <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                            <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                              {pform.label}
                            </InputLabel>
                          </Grid>
                          <Grid item md={4.5} sm={6} xs={6}>
                            <Element name={pform.fields[0].id}></Element>
                            <FormControl fullWidth margin='normal' error={fieldState.invalid} sx={{mt: {xs: 1, md: 2}}}>
                              <Select
                                labelId='kind_select'
                                {...field}
                                disabled={pform.fields[0].disable}
                                color="secondary"
                                onChange={(evt) =>{
                                  setKind(evt.target.value);
                                  setValue(pform.fields[0].id, evt.target.value);
                                  setValue('companyName', '');
                                  setValue('postalcode', '');
                                  setValue('state', '');
                                  setValue('city', '');
                                  setValue('street', '');
                                  setValue('houjinNo', '');
                                }}
                              >
                                <MenuItem value='' sx={{color:'gray'}}>選択してください</MenuItem>
                                {pform.fields[0].options.map((opt) =>(
                                  <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>{fieldState.error?.message}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item md={4.5} sm={6} xs={6}></Grid>
                        </Grid>
                      )}
                    />
                  : <Box key={`pf_n_${idx}`}></Box>
                )))
              }
            </Box>
            </Box>
          ))}
          <Box sx={{position: 'sticky', bottom: 3, width: '100%', left: {xs: 0, md: '15vh'}, mt: 2}}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{borderRadius: 5, width:  {md: '30%', xs: '45%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
            >
              登録
            </Button>
          </Box>
        </form>
      }
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Box>
  );
}