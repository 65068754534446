import React, { useEffect, useLayoutEffect, useState } from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { Box, Typography } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { Top } from "./routes/Top";
import { Signin } from "./routes/auth/Signin";
import { Signup } from "./routes/auth/Signup";
import { Profile } from './routes/profile/Profile';
import { ProfileEdit } from './routes/profile/Edit';
import { ProfileTop } from './routes/profile/Top';
import { SalesTo } from './routes/salesTo/Menu';
import { SalesToEdit } from './routes/salesTo/Edit';
import { SalesToIndex } from './routes/salesTo/Index';
import { SalesToCreateSuccess } from './routes/salesTo/Success';
import { SalesToShow } from './routes/salesTo/Show';
import { GuestSalesToEdit } from './routes/guest/salesTo';
import { ProfileConfirm } from './routes/profile/Confirm';
import { ProfileSuccess } from './routes/profile/Success';
import { FactoringMenu } from './routes/factoring/Menu';
import { FactoringIndex } from './routes/factoring/Index';
import { FactoringEdit } from './routes/factoring/Edit';
import { FactoringConfirm } from './routes/factoring/Confirm';
import { FactoringSuccess } from './routes/factoring/Success';
import { FactoringMethod } from './routes/factoring/Method';
import { ExaminationIndex } from './routes/exampnation/Index';
import { Examination } from './routes/exampnation/Show';
import { ExaminationCancel } from './routes/exampnation/Cancel';
import { ExaminationCancelSuccess } from './routes/exampnation/CancelSuccess';
import { NotFound } from './routes/NotFound';
import { FileuploadIndex } from './routes/fileupload/Index';
import { FileuploadEdit } from './routes/fileupload/Edit';
import { FileuploadConfirm } from './routes/fileupload/Confirm';
import { FileuploadSuccess } from './routes/fileupload/Success';
import { TermsOfService } from './routes/TermsOfService';
import { Faq } from './routes/Faq';
import { PaymentList } from './routes/PaymentList';
import { ScrollToTop } from './routes/ScrollToTop';
import { AuthUserProvider } from './providers/AuthUser';
import { RouteAuthGuard } from './components/RouteAuthGuard';
import { Ekyc } from './routes/Ekyc';
import { SignupSuccess } from './routes/auth/SignupSuccess';
import { SendEmail } from './routes/auth/SignupSendEmail';
import { PasswordSendEmail } from './routes/auth/PasswordSendEmail';
import { SignupInfo } from './routes/auth/SignupInfo';
import { Passcode } from './routes/auth/Passcode';
import { Member } from './routes/auth/Member';
import { Password } from './routes/auth/Password';
import { PasswordEdit } from './routes/auth/PasswordEdit';
import { DeleteEdit } from './routes/delete/Edit';
import { DeleteConfirm } from './routes/delete/Confirm';
import { DeleteComplete } from './routes/delete/Complete';
import { PasswordConfirm } from './routes/auth/PasswordConfirm';
import { EkycSuccess } from './routes/EkycSuccess';
import { PrivacyPolicy } from './routes/PrivacyPolicy';
import { PasswordEditSuccess } from './routes/auth/PasswordEditSuccess';
import { PageTitle } from './components/PageTitle';
import { BottomButtons } from './components/BottomButtons';
import { RouteNotAuthGuard } from './components/RouteNotAuthGuard';
import { RouteAdminAuthGuard } from './components/RouteAdminAuthGuard';
import { Admin } from './routes/admin/Admin';
import { AdminTop } from './routes/admin/AdminTop';
import { AdminPaymentList } from './routes/admin/AdminPaymentList';
import { AdminExamination } from './routes/admin/examination/Show';
import { AdminFileuploadEdit } from './routes/admin/fileupload/Edit';
import { AdminExaminationCancel } from './routes/admin/examination/Cancel';
import { AdminExaminationIndex } from './routes/admin/examination/Index';
import { AdminFactoringMenu } from './routes/admin/factoring/Menu';
import { AdminFactoringIndex } from './routes/admin/factoring/Index';
import { AdminFactoringEdit } from './routes/admin/factoring/Edit';
import { AdminFactoringConfirm } from './routes/admin/factoring/Confirm';
import { AdminFactoringMethod } from './routes/admin/factoring/Method';
import { AdminProfileTop } from './routes/admin/profile/Top';
import { AdminProfile } from './routes/admin/profile/Profile';
import { AdminProfileConfirm } from './routes/admin/profile/Confirm';
import { AdminProfileEdit } from './routes/admin/profile/Edit';
import { AdminSalesToShow } from './routes/admin/salesTo/Show';
import { AdminSalesToIndex } from './routes/admin/salesTo/Index';
import { AdminSalesToEdit } from './routes/admin/salesTo/Edit';
import { AdminLogin } from './routes/admin/AdminLogin';
import { AdminTermsOfService } from './routes/admin/AdminTermsOfService';
import { AdminFaq } from './routes/admin/AdminFaq';
import { AdminDeleteEdit } from './routes/admin/delete/Edit';
import { AdminDeleteConfirm } from './routes/admin/delete/Confirm';
import { ForgetId } from './routes/auth/ForgetId';
import { ForgetIdSuccess } from './routes/auth/ForgetIdSuccess';
import { AdminFileuploadIndex } from './routes/admin/fileupload/Index';
import { FooterText } from './components/FooterText';
import { GuestSalesToSuccess } from './routes/guest/salesToSuccess';

const Router: React.FC = () =>{
  return (
      <Box>
        <AuthUserProvider>
        <Box>
          <Header />
        </Box>
        <Box sx={{display: 'flex', mt: {md: 8, xs: 16}}}>
          <Sidebar />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              pl: {md: 9, xs: 2},
              pr: {md: 9, xs: 2},
              pt: {md: 3, xs: 2},
              pb: {md: 5, xs: 2},
              width: { md: `calc(100% - 280px)`, xs: '100%'},
              borderRadius: {md: 7, xs: 5},
              backgroundColor: {md: '#FFFFFF', xs: '#FCD19C'},
              mr: {md: 2, xs: 1},
              mt: 1,
              ml: {md: '200px', xs: 1},
              minHeight: {md: '80vh', xs: '10vh'}
            }}
          >
          <ScrollToTop />
          <Box sx={{display: {xs: 'none', md: 'block'}}}>
            <PageTitle />
          </Box>
          <Routes>
            <Route path='/login' element={< RouteNotAuthGuard component={<Signin/>} redirect="/"/>} />
            <Route path='/login/password_resetting' element={<Password/>} />
            <Route path='/login/password_resetting-2' element={<PasswordSendEmail />} />
            <Route path='/login/password_resetting-3' element={<PasswordEdit />} />
            <Route path='/login/password_resetting/completed' element={<PasswordEditSuccess/>} />
            <Route path='/login/forget_id' element={<ForgetId/>} />
            <Route path='/login/forget_id/success' element={<ForgetIdSuccess/>} />
            <Route path='/sign_up' element={< RouteNotAuthGuard component={<Signup/>} redirect="/"/>} />
            <Route path='/sign_up/send_email' element={<SendEmail />} />
            <Route path='/sign_up/completed' element={<SignupSuccess />} />
            <Route path='/sign_up/confirmation' element={<PasswordConfirm />} />
            <Route path='/sign_up/info' element={<RouteAuthGuard component={<SignupInfo />} redirect="/login" /> } />
            <Route path='/sign_up/password' element={<RouteAuthGuard component={<Passcode />} redirect="/login" /> } />
            <Route path='/' element={ <RouteAuthGuard component={<Top/>} redirect="/login" /> }/>
            <Route path='/admin' element={ <RouteAdminAuthGuard component={<Admin/>} redirect="/admin/login"/>} />
            <Route path='/admin/login' element={ <RouteNotAuthGuard component={<AdminLogin/>} redirect="/login"/>} />
            <Route path="/admin/terms_of_service" element={<RouteAdminAuthGuard component={<AdminTermsOfService/>}  redirect="/admin/login"/>} />
            <Route path="/admin/faq" element={ <RouteAdminAuthGuard component={<AdminFaq />} redirect="/admin/login" /> } />
            <Route path='/admin/top' element={ <RouteAdminAuthGuard component={<AdminTop/>} redirect="/admin/login"/>} />
            <Route path='/admin/payment_list' element={ <RouteAdminAuthGuard component={<AdminPaymentList/>} redirect="/admin/login"/>} />
            <Route path="/admin/status_detail/:id" element={ <RouteAdminAuthGuard component={<AdminExamination />} redirect="/admin/login" /> } />
            <Route path="/admin/status_list" element={ <RouteAdminAuthGuard component={<AdminExaminationIndex />} redirect="/admin/login" /> } />
            <Route path="/admin/file_addition/:id" element={ <RouteAdminAuthGuard component={<AdminFileuploadEdit />} redirect="/admin/login" /> } />
            <Route path="/admin/file_addition_list" element={ <RouteAdminAuthGuard component={<AdminFileuploadIndex/>} redirect="/admin/login" /> } />
            <Route path="/admin/status_cancel/confirm/:id" element={ <RouteAdminAuthGuard component={<AdminExaminationCancel />} redirect="/admin/login" /> } />
            <Route path="/admin/factoring/top" element={ <RouteAdminAuthGuard component={<AdminFactoringMenu />} redirect="/admin/login" /> } />
            <Route path="/admin/factoring/app_list" element={ <RouteAdminAuthGuard component={<AdminFactoringIndex />} redirect="/admin/login" /> } />
            <Route path="/admin/factoring/app/new" element={ <RouteAdminAuthGuard component={<AdminFactoringEdit />} redirect="/admin/login" /> } />
            <Route path="/admin/factoring/app/confirm" element={ <RouteAdminAuthGuard component={<AdminFactoringConfirm />} redirect="/admin/login" /> } />
            <Route path="/admin/factoring/app/draft" element={ <RouteAdminAuthGuard component={<AdminFactoringEdit />} redirect="/admin/login" /> } />
            <Route path="/admin/factoring/app-2" element={ <RouteAdminAuthGuard component={<AdminFactoringEdit />} redirect="/admin/login" /> } />
            <Route path="/admin/factoring/app-2/confirm" element={ <RouteAdminAuthGuard component={<AdminFactoringConfirm />} redirect="/admin/login" /> } />
            <Route path="/admin/factoring/app" element={ <RouteAdminAuthGuard component={<AdminFactoringMethod />} redirect="/admin/login" /> } />
            <Route path='/admin/profile' element={ <RouteAdminAuthGuard component={<AdminProfileTop />} redirect="/admin/login" /> } />
            <Route path='/admin/myprofile/:id'element={ <RouteAdminAuthGuard component={<AdminProfile />} redirect="/admin/login" /> } />
            <Route path='/admin/myprofile/edit/authentication/:id'element={ <RouteAdminAuthGuard component={<AdminProfileConfirm />} redirect="/admin/login" /> } />
            <Route path="/admin/myprofile/edit/:id" element={ <RouteAdminAuthGuard component={<AdminProfileEdit />} redirect="/admin/login" /> } />
            <Route path='/admin/client_info/:id' element={ <RouteAdminAuthGuard component={<AdminSalesToShow />} redirect="/admin/login" /> } />
            <Route path='/admin/client_list' element={ <RouteAdminAuthGuard component={<AdminSalesToIndex />} redirect="/admin/login" /> } />
            <Route path='/admin/client_register' element={ <RouteAdminAuthGuard component={<AdminSalesToEdit />} redirect="/admin/login" /> } />
            <Route path="/admin/delete/:id" element={ <RouteAdminAuthGuard component={<AdminDeleteEdit />} redirect="/admin/login" /> } />
            <Route path="/admin/delete/confirm/:id" element={ <RouteAdminAuthGuard component={<AdminDeleteConfirm />} redirect="/admin/login" /> } />
            <Route path='/top' element={ <RouteAuthGuard component={<Top/>} redirect="/login" /> }/>
            <Route path='/member/:id' element={ <RouteAuthGuard component={<ProfileEdit />} redirect="/login" /> } />
            <Route path='/login2' element={ <RouteAuthGuard component={<Signin />} redirect="/login" /> } />
            <Route path='/sign_up_2' element={ <RouteAuthGuard component={<Signup />} redirect="/login" /> } />
            <Route path='/sign_up/send_email' element={ <RouteAuthGuard component={<SendEmail />} redirect="/login" /> } />
            <Route path='/sign_up_2/password' element={ <RouteAuthGuard component={<Top />} redirect="/login" /> } />
            <Route path='/profile' element={ <RouteAuthGuard component={<ProfileTop />} redirect="/login" /> } />
            <Route path='/myprofile/:id'element={ <RouteAuthGuard component={<Profile />} redirect="/login" /> } />
            <Route path='/myprofile/edit/authentication/:id'element={ <RouteAuthGuard component={<ProfileConfirm />} redirect="/login" /> } />
            <Route path='/myprofile/edit/completed/:id'element={ <RouteAuthGuard component={<ProfileSuccess />} redirect="/login" /> } />
            <Route path="/myprofile/edit/:id" element={ <RouteAuthGuard component={<ProfileEdit />} redirect="/login" /> } />
            <Route path='/client' element={ <RouteAuthGuard component={<SalesTo />} redirect="/login" /> } />
            <Route path='/client_info/:id' element={ <RouteAuthGuard component={<SalesToShow />} redirect="/login" /> } />
            <Route path='/client_list' element={ <RouteAuthGuard component={<SalesToIndex />} redirect="/login" /> } />
            <Route path='/client_register' element={ <RouteAuthGuard component={<SalesToEdit />} redirect="/login" /> } />
            <Route path="/client_info/completed/:id" element={ <RouteAuthGuard component={<SalesToCreateSuccess />} redirect="/login" /> } />
            <Route path="/factoring/top" element={ <RouteAuthGuard component={<FactoringMenu />} redirect="/login" /> } />
            <Route path="/factoring/app_list" element={ <RouteAuthGuard component={<FactoringIndex />} redirect="/login" /> } />
            <Route path="/factoring/app/new" element={ <RouteAuthGuard component={<FactoringEdit />} redirect="/login" /> } />
            <Route path="/factoring/app/confirm" element={ <RouteAuthGuard component={<FactoringConfirm />} redirect="/login" /> } />
            <Route path="/factoring/app/completed" element={ <RouteAuthGuard component={<FactoringSuccess />} redirect="/login" /> } />
            <Route path="/factoring/app/draft" element={ <RouteAuthGuard component={<FactoringEdit />} redirect="/login" /> } />
            <Route path="/factoring/app-2" element={ <RouteAuthGuard component={<FactoringEdit />} redirect="/login" /> } />
            <Route path="/factoring/app-2/confirm" element={ <RouteAuthGuard component={<FactoringConfirm />} redirect="/login" /> } />
            <Route path="/factoring/app-2/completed" element={ <RouteAuthGuard component={<FactoringSuccess />} redirect="/login" /> } />
            <Route path="/factoring/app" element={ <RouteAuthGuard component={<FactoringMethod />} redirect="/login" /> } />
            <Route path="/status_list" element={ <RouteAuthGuard component={<ExaminationIndex />} redirect="/login" /> } />
            <Route path="/status_detail/:id" element={ <RouteAuthGuard component={<Examination />} redirect="/login" /> } />
            <Route path="/status_cancel/confirm/:id" element={ <RouteAuthGuard component={<ExaminationCancel />} redirect="/login" /> } />
            <Route path="/status_cancel/completed/" element={ <RouteAuthGuard component={<ExaminationCancelSuccess />} redirect="/login" /> } />
            <Route path="/file_addition_list" element={ <RouteAuthGuard component={<FileuploadIndex/>} redirect="/login" /> } />
            <Route path="/file_addition/:id" element={ <RouteAuthGuard component={<FileuploadEdit />} redirect="/login" /> } />
            <Route path="/file_addition/confirm/:id" element={ <RouteAuthGuard component={<FileuploadConfirm />} redirect="/login" /> } />
            <Route path="/file_addition/completed" element={ <RouteAuthGuard component={<FileuploadSuccess />} redirect="/login" /> } />
            <Route path="/payment_list/:id/completed" element={ <RouteAuthGuard component={<Top />} redirect="/login" /> }/>
            <Route path="/terms_of_service" element={<TermsOfService />} />
            <Route path="/faq" element={ <RouteAuthGuard component={<Faq />} redirect="/login" /> } />
            <Route path="/delete/:id" element={ <RouteAuthGuard component={<DeleteEdit />} redirect="/login" /> } />
            <Route path="/delete/confirm/:id" element={ <RouteAuthGuard component={<DeleteConfirm />} redirect="/login" /> } />
            <Route path="/delete/completed" element={ <DeleteComplete />} />
            <Route path="/payment_list" element={ <RouteAuthGuard component={<PaymentList />} redirect="/login" /> } />
            <Route path="/ekyc" element={ <RouteAuthGuard component={<Ekyc />} redirect="/login" /> } />
            <Route path="/ekyc/success" element={ <RouteAuthGuard component={<EkycSuccess />} redirect="/login" /> } />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/sold_to" element={<GuestSalesToEdit />} />
            <Route path="/sold_to/completed/:id" element={<GuestSalesToSuccess />} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
          <FooterText/>
          </Box>
        </Box>
        <BottomButtons/>
        </AuthUserProvider>
      </Box>
  )
}

export default Router;
