import {client} from "./client"
import Cookies from 'js-cookie';

export const getAgreements = () => {
  return client.get(`/agreement?user_id=${Cookies.get('currentUserId')}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  })
}

export const getAgreement = (opportunity_id: string) =>{
  return client.get(`/opportunity/${opportunity_id}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  })
}
