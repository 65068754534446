import React, { useEffect, useState} from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, FormControl, TableRow, InputLabel, Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { useParams, useLocation } from "react-router-dom";
import { DragDropFileUpload } from "../../../components/DragDropFileUpload"
import { Controller, useForm } from 'react-hook-form';
import dayjs from "dayjs";
import { statusColors } from "../../../common/opportunity";
import { getAgreement, getFile, getOpportunityById } from "../../../lib/api/admin";

interface Column {
  id: 'application_date' | 'status' | 'name' | 'amount' | 'payment_date';
  label: string;
  minWidth?: number;
  align?: 'center';
  type?: string;
  format?: (value: number) => string;
  secondRow: string;
  secondLabel: string;
}

const columns: Column[] = [
  { id: 'application_date', label: '申請日', secondLabel: '契約締結日', minWidth: 170, secondRow: 'contract_date', align: 'center', type: 'date'},
  { id: 'status', label: 'ステイタス', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
  { id: 'name', label: '取引先', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
  { id: 'amount', label: '買取（希望）額', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
  { id: 'payment_date', label: '請求書の支払期日', secondLabel: 'バイオンへの振込期日', minWidth: 170, secondRow: 'bion_date', align: 'center', type: 'date'}
]

const mobileColumns: Column[] = [
  { id: 'application_date', label: '申請日', secondLabel: '契約締結日', minWidth: 170, secondRow: 'contract_date', align: 'center', type: 'date'},
  { id: 'status', label: 'ステイタス', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
  { id: 'name', label: '取引先', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
  { id: 'amount', label: '買取（希望）額', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
  { id: 'payment_date', label: '請求書の支払期日', secondLabel: 'バイオンへの振込期日', minWidth: 170, secondRow: 'bion_date', align: 'center', type: 'date'}
]

interface fileFormInfo {
  seikyuusyo: FileList;
  tsuutyou: FileList;
  otherFile: FileList;
}

interface FileInfo {
  filename: string;
  key: string;
  id: string;
}

function createFileInfo(
  filename: string,
  key: string,
  id: string
): FileInfo{
  return {filename, key, id}
}

interface Data {
  application_date: string;
  contract_date: string;
  status: string;
  name: string;
  amount: number;
  payment_date: string;
}

function createData(
  application_date: string,
  contract_date: string,
  status: string,
  name: string,
  amount: number,
  payment_date: string,
): Data{
  return {application_date, contract_date, status, name, amount, payment_date}
}

export const AdminFileuploadEdit = () =>{
  const location = useLocation();
  const [listData, setListData] = useState<Data[]>([]);
  const {id} = useParams();
  const { control, handleSubmit, setValue } = useForm();
  const [returnFileInfo, setReturnFileInfo] = useState<fileFormInfo>(location.state);

  const handleGetOpportunities = async () => {
    // TODO: ログインユーザのIDを設定する
    if(id == null) return; 
    const res = await getOpportunityById(id);

    if (res.status === 200) {
      const lists : Array<Data> = [];
      const rec = res.data.record;
      const res2 = await getAgreement(rec.id);
      let companyString = '';
      if(rec.selectedAccountsReceivables.length > 0){
        const count = rec.selectedAccountsReceivables.length - 1;
        companyString = rec.selectedAccountsReceivables[0].companyname ? rec.selectedAccountsReceivables[0].companyname : rec.selectedAccountsReceivables[0].name;
        if(count > 0){
          companyString += ' 他' + count + '社'
        }
      }
      lists.push(createData(rec.createddate || '-', res2.data.record.aContractdateC ? res2.data.record.aContractdateC : '-', rec.portalStagename, companyString || '-', rec.exerciseprice ? rec.exerciseprice.toLocaleString() : '-', rec.kaisyuyoteibi || '-'));
      setListData(lists);
    }
  }

  useEffect(() => {
    handleGetOpportunities()
    if(returnFileInfo){
      setValue('seikyuusyo', returnFileInfo.seikyuusyo);
      setValue('tsuutyou', returnFileInfo.tsuutyou);
      setValue('otherFile', returnFileInfo.otherFile);
    }
  }, [])

  const submitForm = async (evt) =>{
    return;
  }

  const handleFileClick = async (s3_id) =>{
    const res = await getFile(s3_id);
    if(res.status == 200){
      open(res.data.url, '_blank');
    }
  }

  return (
    <>
      <Box sx={{display: {xs: 'none', md: 'block'}}}>
      <Paper sx={{ width: '100%' }}>
        <TableContainer  sx={{ maxHeight: '70vh' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) =>(
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth}}
                    sx={{backgroundColor: "#FFF", p: 1, fontWeight: 'bold'}}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listData
                .map((row) =>{
                  return (
                    <TableRow hover tabIndex={-1} key={id}>
                      {columns.map((column) =>{
                        const value = column.type == 'date' ? dayjs(row[column.id]).format('YYYY-MM-DD') : row[column.id];
                        return(
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value }
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      </Box>
      <Box sx={{ width: '100%', display: {xs: 'block', md: 'none'}}}>
      <Grid
        container
        direction="column"
        alignContent="center"
        justifyContent="center"
        sx={{flexWrap: 'nowrap', WebkitFlexWrap: 'nowrap'}}
      >
      {listData
        .map((row, idx) =>{
          const statusColor = row.status ? statusColors[row.status] : "white";
          return (
            <Grid item xs="auto" key={`mobile_card_${idx}`}>
              <Card
                variant="outlined"
                sx={{
                  mt: 1,
                  borderRadius: 3,
                  pb: 1,
                  mb: 2
                }}
              >
                <CardContent sx={{borderBottom: '1px solid #E7E6E0', pb: 1}}>
                  <Grid container sx={{mt: 1}}>
                    <Grid item xs={3} sx={{
                      m: 'auto',
                      textAlign: 'right',
                      pr: 1,
                    }}>
                      <Typography>
                        申請日
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{
                      m: 'auto',
                      pl: 1
                    }}>
                      <Typography>
                        {row.application_date}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography sx={{alignContent: 'right', borderRadius: 5, maxWidth: '120px', fontWeight: 'bold', border: 2, borderColor: statusColor, color: statusColor, backgroundColor: "#FFF", m: 'auto'}}
                        >
                        {row.status}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent>
                  <Grid container sx={{mt: 1}}>
                    <Grid item xs={12} sx={{
                      m: 'auto',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      pl: 3
                    }}>
                      契約日
                    </Grid>
                    <Grid item xs={12} sx={{
                      m: 'auto',
                      textAlign: 'left',
                      pl: 5
                    }}>
                      <Typography>
                        {row.contract_date}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{mt: 1}}>
                    <Grid item xs={12} sx={{
                      m: 'auto',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      pl: 3
                    }}>
                      売掛先
                    </Grid>
                    <Grid item xs={12} sx={{
                      m: 'auto',
                      textAlign: 'left',
                      pl: 5
                    }}>
                      <Typography>{row.name}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{mt: 1}}>
                    <Grid item xs={12} sx={{
                      m: 'auto',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      pl: 3
                    }}>
                      買取（希望）額
                    </Grid>
                    <Grid item xs={12} sx={{
                      m: 'auto',
                      textAlign: 'left',
                      pl: 5
                    }}>
                      <Typography>{row.amount}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{mt: 1}}>
                    <Grid item xs={12} sx={{
                      m: 'auto',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      pl: 3
                    }}>
                      売掛先からの入金予定日
                    </Grid>
                    <Grid item xs={12} sx={{
                      m: 'auto',
                      textAlign: 'left',
                      pl: 5
                    }}>
                      <Typography>{row.payment_date}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })
      }
      </Grid>
      </Box>
      <Box sx={{ marginTop: 3 }}>
      <form onSubmit={handleSubmit(submitForm)}>
        <Box sx={{
          borderLeft: "5px solid #D16960",
          textAlign: 'left',
          mt: 2
        }}>
          <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>追加提出書類</Typography>
          <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>審査に必要な書類をご提出ください</Typography>
          <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}></Typography>
        </Box>
        <Box
          sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
        >
          <Controller
            control={control}
            name='seikyuusyo'
            defaultValue=""
            render={({ field: {onChange, value }}) =>(
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    請求書
                  </InputLabel>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                    請求書、注文書、契約書など売掛金の内容が分かる書類をご提出ください
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                  <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 0}}}>
                    <DragDropFileUpload onFileUpload={onChange} input_id={1} inputValue={value}/>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name='tsuutyou'
            defaultValue=""
            render={({ field: {onChange, value }}) =>(
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    通帳（表紙付直近2か月分）
                  </InputLabel>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                  売掛先から入金がある通帳をご提出ください
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                  <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 0}}}>
                    <DragDropFileUpload onFileUpload={onChange} input_id={2} inputValue={value}/>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name='otherFile'
            defaultValue=""
            render={({ field: {onChange, value }}) =>(
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    その他書類
                  </InputLabel>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                    日報や納品書など売掛金の存在を確認できる補足書類がございましたらご提出ください
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                  <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 0}}}>
                    <DragDropFileUpload onFileUpload={onChange} input_id={3} inputValue={value}/>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          />
        </Box>
      </form>
      </Box>
    </>
  );
};