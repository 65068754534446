import React, { useEffect, useState } from 'react';
import { Button, TextField, InputLabel, Select, MenuItem, FormControl, Hidden, FormHelperText, Grid, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { DeleteInput, deleteDefaultValues, deletesFormInfo, deleteValidationRules } from '../../common/profile';
import Cookies from 'js-cookie';
import { getProfile } from '../../lib/api/profile';

export const DeleteEdit = () =>{
  const { control, handleSubmit, setValue} = useForm<DeleteInput>({defaultValues: deleteDefaultValues});
  const navigate = useNavigate();

  const handleGetProfile = async () =>{
    const res = await getProfile();
    if(res.status === 200){
      const lastOppState = res.data.record.lastOppStatus;
      const tradingCondition = res.data.record.tradingCondition;
      if(lastOppState && lastOppState != '見込み' && lastOppState != 'NG' && lastOppState != 'キャンセル' && lastOppState != '実行済み'){
        navigate('/top');
      }
      if(tradingCondition == '契約中'){
        navigate('/top');
      }
    }
  }

  useEffect(() => {
    handleGetProfile();
  }, [])

  const onSubmit: SubmitHandler<DeleteInput> = (evt) =>{
    navigate("/delete/confirm/" +  Cookies.get('currentUserId'), {state: evt});
  }

  const onClickCancel = () =>{
    navigate("/faq");
  }

  return (
    <Box sx={{pr: {xs: 1, md: 2}, pl: {xs: 1, md: 2}, pb: 2, pt: {xs: 2}, backgroundColor: {xs: "#FFFFFF"}, borderRadius: 2}}>
      <form id="user-delete-form" onSubmit={handleSubmit(onSubmit)}>
        {deletesFormInfo.map((pform) =>{
          if(pform.type == 'string'){
            return (<Controller
              key={pform.id}
              control={control}
              name={pform.id}
              rules={deleteValidationRules[pform.id]}
              render={({ field, fieldState }) => (
                <Grid container>
                  <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                    <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                      {pform.label}
                    </InputLabel>
                  </Grid>
                  <Grid item md={9} sm={12} xs={12}>
                    <TextField
                      {...field}
                      fullWidth
                      sx={{mt: {xs: 0, md: 2}}}
                      margin="normal"
                      color="secondary"
                      placeholder="入力してください"
                      disabled={pform.disable ? pform.disable : false}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  </Grid>
                </Grid>
              )}
            />);
          }else if(pform.type == 'select'){
            return (<Controller
              key={pform.id}
              control={control}
              name={pform.id}
              rules={deleteValidationRules[pform.id]}
              render={({ field, fieldState }) => (
                <Grid container>
                  <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                    <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                      {pform.label}
                    </InputLabel>
                  </Grid>
                  <Grid item md={9} sm={12} xs={12}>
                    <FormControl fullWidth margin='normal' error={fieldState.invalid} sx={{mt: {xs: 0, md: 2}}}>
                      <Select
                        labelId='kind_select'
                        {...field}
                        color="secondary"
                        disabled={pform.disable ? pform.disable : false}
                        onChange={(evt) =>{setValue(pform.id, evt.target.value)}}
                      >
                        <MenuItem value='' sx={{color:'gray'}}>選択してください</MenuItem>
                        {pform.options.map((opt) =>(
                          <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            />)
          }else if(pform.type == 'hidden'){
            return (<Controller
              key={pform.id}
              control={control}
              name={pform.id}
              render={({ field }) => ( <Hidden/>)}
            />);
          }
        })}
        <Box sx={{position: 'sticky', bottom: 3, width: '100%', left: {xs: 0, md: '15vh'}, mt: 2}}>
          <Button
            variant='contained'
            color='warning'
            onClick={() => onClickCancel()}
            sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
          >
            次へ
          </Button>
        </Box>
      </form>
    </Box>
  );
}