import {client} from "./client"
import {Input} from "../../common/accounts_receivable"
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

interface indexParams {
  user_id: string;
}

// 動作確認用
export const getAccountsReceivables = () => {
  return client.get(`/accounts_receivable?user_id=${Cookies.get('currentUserId')}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const getAccountsReceivable = (id: string) =>{
  return client.get(`/accounts_receivable/${id}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const createAccountsReceivable = (params: Input) =>{
  const formData = new FormData();
  if(params.kind) formData.append('accounts_receivable[type]', params.kind);
  if(params.hpAddress) formData.append('accounts_receivable[hp]', params.hpAddress);
  if(params.houjinNo) formData.append('accounts_receivable[corporationnumber]', params.houjinNo);
  if(params.companyName){
    formData.append('accounts_receivable[name]', params.companyName);
    formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][companyname]]', params.companyName)
  }
  if(params.postalcode){
    formData.append('accounts_receivable[postalcode]', params.postalcode);
    formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][postalcode]', params.postalcode);
  }
  if(params.state){
    formData.append('accounts_receivable[state]', params.state);
    formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][state]', params.state);
  }
  if(params.city){
    formData.append('accounts_receivable[city]', params.city);
    formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][city]', params.city);
  }
  if(params.street){
    formData.append('accounts_receivable[street]', params.street);
    formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][street]', params.street);
  }
  if(params.other){
    formData.append('accounts_receivable[other]', params.other);
    formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][other]', params.other);
  }
  if(params.fixDate) formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][closingdate]]', params.fixDate);
  if(params.paymentDate) formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][paymentdate]]', params.paymentDate);
  if(params.manager) formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][personincharge]]', params.manager);
  if(params.phone) formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][telno]]', params.phone);
  formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][registrationdate]]', dayjs().toISOString());
  formData.append('user_id', Cookies.get('currentUserId'));
  return client.post('/accounts_receivable', formData, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const updateAccountsReceivable = (params: Input, id: string) =>{
  const formData = new FormData();
  formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][closingdate]]', params.fixDate);
  formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][dateofpayment]]', params.paymentDate);
  formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][personincharge]]', params.manager);
  formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][telno]]', params.phone);
  return client.put(`/accounts_receivable/${id}`, formData, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}