import React, { useEffect, useState} from "react";
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

export const SalesTo = () =>{
  const navigate = useNavigate();

  const onClickSalesToNew = () =>{
    // TODO: 売掛先登録画面へ遷移
    navigate("/sales_to/edit");
  }

  const onClickSalesToIndex = () =>{
    // TODO: 売掛先情報へ遷移
    navigate("/sales_to/index");
  }

  return (
      <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>{onClickSalesToNew()}}
          >
            新規売掛先の登録
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>{onClickSalesToIndex()}}
          >
            登録した売掛先の確認
          </Button>
      </div>
  );
}