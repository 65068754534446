import {client} from "./client"
import Cookies from 'js-cookie';

interface Corporate {
  name: string;
}

interface CorporateNum{
  code: string;
}

// 動作確認用
export const getCorporates = (params: Corporate) => {
  return client.get(`/corporate_name/search?name=${params.name}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const getCorporateByCode = (params: CorporateNum) =>{
  return client.get(`/corporate_name/search?code=${params.code}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}
