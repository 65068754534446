import React, { useEffect, useState} from "react";
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ImageButton from "../../../components/ImageButton";
import { ErrorModal } from "../../../components/ErrorModal";
import { ConfirmModal } from "../../../components/ConfirmModal";
import { getDraftOpportunityByUserId, getOpportunities, getProfile } from "../../../lib/api/admin";

interface Oppotunity {
  draft: string;
  id: string;
}

export const AdminFactoringMenu = () =>{
  const [oppotunity, setOpportunity] = useState<Oppotunity>({draft: '', id: ''});
  const [isDisableCreate, setIsDisableCreate] = useState(true);
  const navigate = useNavigate();
  const [isDisableCopy, setIsDisableCoyp] = useState(true);
  const [isLoad, setIsLoad] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState<any>();
  const [confirmButtonLength, setConfirmButtonLength] = useState(2);

  const handleOpenConfirmModal = () =>{
    setConfirmMessage(<Typography>下書きがありますが、続けますか？</Typography>);
    setConfirmModalOpen(true);
  }

  const handleOkClick = () =>{
    setConfirmModalOpen(false);
    navigate("/admin/factoring/app_list");
  }

  const handleCancelClick = () =>{
    setConfirmModalOpen(false);
  }

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const handleGetDraftOpportunity = async () => {
    // TODO: 本来はユーザIDを設定する
    const res = await getDraftOpportunityByUserId();
    const profileRes = await getProfile();
    const oppsRes = await getOpportunities();

    if (res.status === 200) {
      setOpportunity(res.data.record);
      const lastStatus = profileRes.data.record.lastOppStatus;
      const opps = oppsRes.data.records;
      if(!profileRes.data.record.identification){
        setIsDisableCreate(true);
        setIsDisableCoyp(true);
      }else if(!lastStatus || lastStatus == '見込み' || lastStatus == 'NG' || lastStatus == 'キャンセル' || lastStatus == '実行済み'){
        setIsDisableCreate(false);
        if(opps.length > 0) setIsDisableCoyp(false);
        else setIsDisableCoyp(true);
      }else{ 
        setIsDisableCreate(true);
        setIsDisableCoyp(true)
      }
      setIsLoad(true);
    }
  }

  useEffect(() => {
    handleGetDraftOpportunity();
  }, [])

  const onClickFactoringNew = () =>{
    if(isDisableCreate){
      setErrorMessage(<><Typography>会員情報登録・本人確認がお済みでない</Typography><Typography>または現在審査中・契約手続中のお客様は申請できません。</Typography></>);
      handleOpenErrorModal();
      return;
    }
    if(oppotunity && oppotunity.id){
      navigate("/admin/factoring/app?draftId=" + oppotunity.id);
    }else{
      // 書きかけがなければ新規作成
      navigate("/admin/factoring/app/new");
    }
  }

  const onClickFactoringCopy = () =>{
    // 過去申請一覧へ遷移
    if(isDisableCopy) {
      setErrorMessage(<><Typography>過去に申請がありません。</Typography><Typography>または現在審査中・契約手続き中のお客様は申請できません。</Typography></>);
      handleOpenErrorModal();
      return;
    }
    if(oppotunity && oppotunity.id){

      handleOpenConfirmModal();
    }else{
      navigate("/admin/factoring/app_list");
    }
  }

  const onClickFactoringIndex = () =>{
    // 審査中の手続き一覧
    navigate("/admin/status_list");
  }

  return (
      <Box sx={{
        display: {md: 'flex'},
        height: {
          md: '80%'
        },
        justifyContent: {
          md: 'center'
        },
        alignItems:{
          md: 'center'
        },
        p: {xs: 1, md: 0},
        borderRadius: 2
      }}>
        {isLoad ?
        <>
          <ImageButton onClick={onClickFactoringNew} imgUrl="/icons/icon01.svg" buttonTitle="審査の申し込み" buttonText="審査の申し込みはこちら"/>
          <ImageButton onClick={onClickFactoringCopy} imgUrl="/icons/icon03.svg" buttonTitle="過去申請をコピーして審査の申し込み" buttonText="過去の申請をコピーして審査を申し込む場合はこちら"/>
          <ImageButton onClick={onClickFactoringIndex} imgUrl="/icons/icon05.svg" buttonTitle="申請履歴" buttonText="申請履歴の確認はこちら"/>
        </>
        :
        <></>
        }
        <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
        <ConfirmModal message={confirmMessage} modalOpen={confirmModalOpen} cancelClick={handleCancelClick} okClick={handleOkClick} buttonLength={confirmButtonLength}></ConfirmModal>
      </Box>
  );
}