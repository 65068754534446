import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { getCurrentUser } from "../lib/api/auth";
import { getAdminUser } from "../lib/api/admin_user";

type Props = {
  component: React.ReactNode;
  redirect: string
}

export const RouteAdminAuthGuard: React.FC<Props> = (props) => {
  const signedIn = Cookies.get('_admin_access_token');
  const navigate = useNavigate();
  const location = useLocation();

  const handleGetCurrentUser = async () =>{
    try{
      const ret = await getAdminUser();
      if(ret?.status == 200 && (ret.data?.message == 'ユーザーが存在しません。' || ret.data?.records?.length == 0)){
        Cookies.remove("_admin_access_token");
        Cookies.remove("_admin_client");
        Cookies.remove("_admin_uid");
        navigate('/admin_login');
      }
    }catch(e){
      console.log(e);
      navigate('/admin_login')
    }
  };

  useEffect(() =>{
    handleGetCurrentUser();
  }, [location.pathname])
  
  return (
    signedIn ? <>{props.component}</> : <Navigate to={props.redirect} state={{from:useLocation()}} replace={false} />
  )
}