import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { passwordReset } from "../../lib/api/auth";
import { Button, TextField, Container, Grid, InputLabel, Box, Link as MuiLink, Typography } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import Loading from "../../components/Loading";
import { ErrorModal } from "../../components/ErrorModal";

export const Password = () =>{
  const { control, handleSubmit, setValue, getValues} = useForm({defaultValues: {email: ''}});
  const [isLoading, setIsLoading] = useState(false);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const message = query.get('error');
  const [confirmed, setConfirm] = useState(true);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const navigate = useNavigate();

  useEffect(() =>{
    if(message == '1' && confirmed){
      setConfirm(false);
    }
  }, []);

  useEffect(() =>{
    if(confirmed) return;
    setErrorMessage(<Typography>無効なURLです。再度、パスワード設定を行なってください。</Typography>);
    handleOpenErrorModal();
    setConfirm(true);
  }, [confirmed])

  const handleSignInSubmit = async (e) => {
    if(isLoading) return;
    setIsLoading(true);
    try {
      const res = await passwordReset(e);
      setIsLoading(false);
      navigate('/login/password_resetting-2');
    } catch (e) {
      setIsLoading(false);
      const messages = e.response.data.errors[0].split("\n");
      setErrorMessage(<>{messages.map(m => <Typography>{m}</Typography>)}</>);
      handleOpenErrorModal();
    }
  };
  return (
    <Container
      sx={{
        width: {md: '80%', xs: '95%'},
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        border: 'solid',
        borderColor: '#E1DED0',
        pt: 5,
        pb: 5
      }}
    >
      <form id='password_reset_form' onSubmit={handleSubmit(handleSignInSubmit)}>
        <Grid container>
          <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}></Grid>
          <Grid item md={9} sm={12} xs={12}>
            <Typography sx={{textAlign: 'left'}}>
              登録済みのメールアドレスを入力してください。
            </Typography>
            <Typography sx={{textAlign: 'left'}}>
              パスワードの変更、退会済みで再登録をご希望の方もこちらから再登録してください。
            </Typography>
          </Grid>
        </Grid>
        <Controller
          control={control}
          name='email'
          render={({ field, fieldState }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  メールアドレス
                </InputLabel>
              </Grid>
              <Grid item md={9} sm={12} xs={12}>
                <TextField
                  {...field}
                  fullWidth
                  sx={{mt: {xs: 0, md: 2}}}
                  margin="normal"
                  color="secondary"
                  placeholder="入力してください"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              </Grid>
            </Grid>
          )}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          送信
        </Button>
      </form>
      <MuiLink
        component={Link}
        to="/login"
        underline="hover"
      >ログインする</MuiLink>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Container>
  );
}
