import React, { useEffect, useState} from "react";
import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Box, Grid, Card, CardContent, Typography, Select, MenuItem, FormControl, InputLabel, Link as MuiLink, CardActions } from '@mui/material';
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { searchStatus, statusColors} from "../../../common/opportunity";
import { getAgreements, getFile, getOpportunities } from "../../../lib/api/admin";

interface Column {
  id: 'detailButton' | 'applicationDate' | 'contractDate' | 'status' | 'companyName' | 'amount' | 'dueDate' | 'files';
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
  type?: string;
  sortable: boolean;
}

const columns: Column[] = [
  { id: 'detailButton', label: '', minWidth: 120, align: 'center', sortable: false},
  { id: 'applicationDate', label: '申請日', minWidth: 100, align: 'center', sortable: true, type: 'date'},
  { id: 'contractDate', label: '契約日', minWidth: 100, align: 'center', sortable: true},
  { id: 'status', label: 'ステイタス', minWidth: 120, align: 'center', sortable: true},
  { id: 'companyName', label: '売掛先', minWidth: 130, align: 'center', sortable: true},
  { id: 'amount', label: '買取（希望）額', minWidth: 100, align: 'center', sortable: true},
  { id: 'dueDate', label: '売掛先からの入金予定日', minWidth: 100, align: 'center', sortable: true},
  // { id: 'files', label: '契約書', minWidth: 170, align: 'center', sortable: false}
]

const mobileColumns: Column[] = [
  { id: 'applicationDate', label: '申請日', minWidth: 170, align: 'center', sortable: true, type: 'date'},
  { id: 'status', label: 'ステイタス', minWidth: 170, align: 'center', sortable: true},
  { id: 'contractDate', label: '契約日', minWidth: 170, align: 'center', sortable: true},
  { id: 'companyName', label: '売掛先', minWidth: 170, align: 'center', sortable: true},
  { id: 'amount', label: '買取（希望）額', minWidth: 170, align: 'center', sortable: true},
  { id: 'dueDate', label: '売掛先からの入金予定日', minWidth: 170, align: 'center', sortable: true},
  { id: 'detailButton', label: '', minWidth: 170, align: 'center', sortable: false},
  { id: 'files', label: '契約書', minWidth: 170, align: 'center', sortable: false}
]

interface File {
  name: string;
  id: string;
}

interface Data {
  detailButton: string;
  applicationDate: string;
  contractDate: string;
  status: string;
  companyName: string;
  amount: number;
  dueDate: string;
  files: File[];
}

function createDate(
  detailButton: string,
  applicationDate: string,
  contractDate: string,
  status: string,
  companyName: string,
  amount: number,
  dueDate: string,
  files: File[],
): Data{
  return {detailButton, applicationDate, contractDate, status, companyName, amount, dueDate, files}
}

export const AdminExaminationIndex = () =>{
  const [listData, setListData] = useState<Data[]>([]);
  const [orgListData, setOrgListData] = useState<Data[]>([]);
  // ソート関連は以下
  // sort方法
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  //ソート対象
  const [orderBy, setOrderBy] = useState<string>("applicationDate");
  const [searchYear, setSearchYear] = useState<any>('1');
  const [searchState, setSearchState] = useState<any>('1');
  const [selectYears, setSelectYears] = useState<number[]>([]);

  //ソート対象、方法の変更
  const handleRequestSort = (event:any, property:any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property:any) => (event:any) => {
    handleRequestSort(event, property);
  };

  //ソート実行
  const stableSort = (array:any, comparator:any) => {
    const stabilizedThis = array.map((el:any, index:any) => [el, index]);
    stabilizedThis.sort((a:any, b:any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el:any) => el[0]);
  }

  //比較処理
  const descendingComparator = (a:any, b:any, orderBy:any)=> {
    if (a[orderBy] == null) return 1;
    if (b[orderBy] == null ) return -1;
    if(orderBy == 'amount'){
      if(!b[orderBy] || !a[orderBy]){
        return 0;
      }
      if(Number(b[orderBy].replace(/,/g, '')) < Number(a[orderBy].replace(/,/g, ''))){
        return -1;
      }
      if(Number(b[orderBy].replace(/,/g, '')) > Number(a[orderBy].replace(/,/g, ''))){
        return 1;
      }
      return 0;
    }else{
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }
  const getComparator = (order:any, orderBy:any)=> {
    return order === "desc" 
      ? (a:any, b:any) => descendingComparator(a, b, orderBy)
      : (a:any, b:any) => -descendingComparator(a, b, orderBy);
  }

  const handleGetOpportunities = async () => {
    // TODO: ログインユーザのIDを設定する
    const res = await getOpportunities();
    const res2 = await getAgreements();

    if (res.status === 200 && res2.status === 200) {
      const agreements = res2.data.records;
      const lists : Array<Data> = [];
      res.data.records.forEach(rec =>{
        const firstAgreement = agreements.find((agree) => agree.opportunityC == rec.sfid && agree.aContractdateC);
        let companyString = '';
        if(rec.selectedAccountsReceivables.length > 0){
          const count = rec.selectedAccountsReceivables.length - 1;
          companyString = rec.selectedAccountsReceivables[0].companyname ? rec.selectedAccountsReceivables[0].companyname : rec.selectedAccountsReceivables[0].name;
          if(count > 0){
            companyString += ' 他' + count + '社'
          }
        }
        if(rec.portalStagename && rec.portalStagename != '-') lists.push(createDate(rec.id, rec.createddate, firstAgreement ? firstAgreement.aContractdateC : '-', rec.portalStagename, companyString ? companyString : '-', rec.exerciseprice ? rec.exerciseprice.toLocaleString() : '-', rec.kaisyuyoteibi ? rec.kaisyuyoteibi : '-', rec.files));
      })
      setListData(lists);
      setOrgListData(lists);
    }
  }

  const onClickSearch = () =>{
    if(searchYear != '1' && searchState != '1'){
      setListData(orgListData.filter((row) => row.applicationDate?.indexOf(searchYear) >= 0 && row.status.includes(searchState)));
    }else if(searchYear == '1' && searchState != '1'){
      setListData(orgListData.filter((row) => row.status.includes(searchState)));
    }else if(searchYear != '1' && searchState == '1'){
      setListData(orgListData.filter((row) => row.applicationDate?.indexOf(searchYear) >= 0));
    }else{
      setListData(orgListData);
    }
  }

  useEffect(() => {
    handleGetOpportunities();
    const thisYear = new Date().getFullYear();
    const listYear : number[] = [];
    for(let y=2024; y <= thisYear; y++){
      listYear.push(y);
    }
    setSelectYears(listYear.reverse());
  }, [])

  const handleFileClick = async (s3_id) =>{
    const res = await getFile(s3_id);
    if(res.status == 200){
      open(res.data.url, '_blank');
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
    <Box sx={{ width: '100%', display: {xs: 'none', md: 'block'}}}>
      <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'center'}}>
        <Typography sx={{mr: 1, fontWeight: 'bold'}}>検索条件</Typography>
        <FormControl sx={{width: '210px'}}>
          <Select
            color="secondary"
            defaultValue={'1'}
            sx={{mr: 1}}
            size="small"
            onChange={(event) => setSearchYear(event.target.value)}
          >
            <MenuItem value='1'>申請年</MenuItem>
            {selectYears.map((year) =>(
              <MenuItem key={year} value={year}>{`${year}年`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{width: '210px'}}>
          <Select
            defaultValue={'1'}
            color="secondary"
            sx={{mr: 1}}
            size="small"
            onChange={(event) => setSearchState(event.target.value)}
          >
            <MenuItem value='1'>ステイタス</MenuItem>
            {searchStatus.map((state) =>(
              <MenuItem key={state} value={state}>{state}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>{onClickSearch()}}
          sx={{borderRadius: 5, fontWeight: 'bold', mr: 1}}
          >
          検索
        </Button>
      </Stack>
      <TableContainer  sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            {columns.map((column) =>(
                column.id != 'detailButton' ?
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth}}
                    sortDirection={orderBy === column.id ? order : 'desc'}
                    onClick={createSortHandler(column.id)}
                    sx={{backgroundColor: "#FFF", p: 1, fontWeight: 'bold'}}
                  >
                    {column.label}
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                      sx={{backgroundColor: "#FFF", p: 1, fontWeight: 'bold'}}
                    ></TableSortLabel>
                  </TableCell>
                : <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth}}
                    sortDirection={orderBy === column.id ? order : 'desc'}
                    sx={{backgroundColor: "#FFF", p: 1, fontWeight: 'bold'}}
                  >
                    {column.label}
                  </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(listData, getComparator(order, orderBy))
              .map((row) =>{
                return (
                  <TableRow hover tabIndex={-1} key={row.detailButton}>
                    {columns.map((column, idx) =>{
                      const value = column.type == 'date' && row[column.id] ?
                        dayjs(row[column.id]).format('YYYY-MM-DD') :
                        column.type == 'date' && !row[column.id] ?
                        '-' :
                        row[column.id];
                      const statusColor = column.id == 'status' ? statusColors[value] : "white";
                      return(
                        <TableCell key={column.id} align={column.align} sx={{p: 1}}>
                          {column.id == 'detailButton' 
                            ? <Button
                              variant='contained'
                              component={Link}
                              to={`/admin/status_detail/${row.detailButton}`}
                              sx={{borderRadius: 5, fontWeight: 'bold'}}
                            >
                              詳細を見る
                            </Button>
                            : column.id == 'status'
                            ? <Typography sx={{borderRadius: 5, maxWidth: '120px', fontWeight: 'bold', border: 2, borderColor: statusColor, color: statusColor, backgroundColor: "#FFF", m: 'auto'}}
                            >
                              {value}
                            </Typography> 
                            : column.id == 'files'
                            ? value.map((f, indx) =>{
                              return (
                                <Box key={'file_' + idx + '_' + indx}>
                                <MuiLink onClick={() => handleFileClick(f.id)}>
                                  {f.name}
                                </MuiLink>
                                </Box>
                              )
                            })                         
                            : column.format && typeof value === 'number'
                            ? column.format(value)
                            : value }
                        </TableCell>
                      )
                    })}
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    <Box sx={{ width: '100%', display: {xs: 'block', md: 'none'}}}>
      <Grid
        container
        direction="column"
        alignContent="center"
        justifyContent="center"
        sx={{flexWrap: 'nowrap', WebkitFlexWrap: 'nowrap'}}
      >
        <Grid item xs="auto" key='mobile_search'>
          <Card sx={{mb: 2}}>
            <Typography sx={{mr: 1, mt: 1, mb: 1, fontWeight: 'bold'}}>検索条件</Typography>
            <FormControl sx={{width: '210px'}}>
              <Select
                color="secondary"
                defaultValue={'1'}
                sx={{mr: 1}}
                size="small"
                onChange={(event) => setSearchYear(event.target.value)}
              >
                <MenuItem value='1'>申請年</MenuItem>
                {selectYears.map((year) =>(
                  <MenuItem key={year} value={year}>{`${year}年`}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{width: '210px'}}>
              <Select
                defaultValue={'1'}
                color="secondary"
                sx={{mr: 1}}
                size="small"
                onChange={(event) => setSearchState(event.target.value)}
              >
                <MenuItem value='1'>ステイタス</MenuItem>
                {searchStatus.map((state) =>(
                  <MenuItem key={state} value={state}>{state}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ pb: 1, pt: 1}}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>{onClickSearch()}}
              sx={{borderRadius: 5, fontWeight: 'bold', mr: 1, width: '50%'}}
              >
              検索
            </Button>
            </Box>
          </Card>
        </Grid>
      {listData.map((row, row_id) =>{
        const statusColor = row.status ? statusColors[row.status] : "white";
        return (<Grid item xs="auto" key={row.detailButton}>
          <Card
            variant="outlined"
            sx={{
              mt: 1,
              borderRadius: 3,
              pb: 1,
              mb: 2
            }}
          >
            <CardContent sx={{borderBottom: '1px solid #E7E6E0', pb: 1}}>
              <Grid container sx={{mt: 1}}>
                <Grid item xs={3} sx={{
                  m: 'auto',
                  textAlign: 'right',
                  pr: 1,
                }}>
                  <Typography>
                    申請日
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{
                  m: 'auto',
                  pl: 1
                }}>
                  <Typography>
                    {row.applicationDate}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{alignContent: 'right', borderRadius: 5, maxWidth: '120px', fontWeight: 'bold', border: 2, borderColor: statusColor, color: statusColor, backgroundColor: "#FFF", m: 'auto'}}
                    >
                    {row.status}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Grid container sx={{mt: 1}}>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  pl: 3
                }}>
                  契約日
                </Grid>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  pl: 5
                }}>
                  <Typography>
                    {row.contractDate}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{mt: 1}}>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  pl: 3
                }}>
                  売掛先
                </Grid>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  pl: 5
                }}>
                  <Typography>{row.companyName}</Typography>
                </Grid>
              </Grid>
              <Grid container sx={{mt: 1}}>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  pl: 3
                }}>
                  買取（希望）額
                </Grid>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  pl: 5
                }}>
                  <Typography>{row.amount}</Typography>
                </Grid>
              </Grid>
              <Grid container sx={{mt: 1}}>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  pl: 3
                }}>
                  売掛先からの入金予定日
                </Grid>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  pl: 5
                }}>
                  <Typography>{row.dueDate}</Typography>
                </Grid>
              </Grid>
              {/* <Grid container sx={{mt: 1}}>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  pl: 3
                }}>
                  契約書
                </Grid>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  pl: 5
                }}>
                  {row.files.map((f, indx) =>{
                    return (
                      <Box key={'file_' + row_id + indx}>
                        <MuiLink onClick={() => handleFileClick(f.id)}>
                          {f.name}
                        </MuiLink>
                      </Box>
                    )
                  })}
                </Grid>
              </Grid> */}
            </CardContent>
            <CardActions sx={{display: 'block', pr: 1, pl: 1}}>
              <Button
                variant='contained' 
                component={Link}
                to={`/admin/status_detail/${row.detailButton}`}
                sx={{borderRadius: 5, width: '100%', fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
              >
                詳細を見る
              </Button>
            </CardActions>
          </Card>
        </Grid>
        );
      })
    }
      </Grid>
    </Box>
    </Box>
  );
};