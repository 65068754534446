import React from 'react'
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography} from '@mui/material';
import Cookies from 'js-cookie';

export const SendEmail = () =>{
  const navigate = useNavigate();
  const signedIn = Cookies.get('isSignedIn');

  const onClickCancel = () =>{
    if(signedIn){
      navigate('/login2');
    }else{
      navigate('/login');
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: '#F0EFEA',
        width: {md: '60%'},
        maxHeight: '3500px',
        margin: '0 auto',
        pr: 1,
        pl: 1,
        borderRadius: '5px',
        minWidth: {md: '470px'},
        pt: 1,
        pb: 1,
        mt: {md: 10, xs: 2}
      }}
    >
      <Typography sx={{fontWeight: 'bold', fontSize: 17, pt: 5}}>
        メールアドレス認証  
      </Typography>
      <Typography>support@portal.betrading.jp　から認証メールを送信しました。</Typography>
      <Typography>メールに記載のリンクより会員情報の入力に進んでください。</Typography>
      <Typography>※お客様の迷惑メール対策設定状況によっては、メールが届かない場合があります。しばらく経ってもメールが届かない場合、「support@portal.betrading.jp」からのメールが迷惑メールに設定されていないかをご確認のうえ、こちらから再送信してください。</Typography>
      <Box sx={{mt: 3}}>
        <Button
          variant='contained'
          onClick={() => onClickCancel()}
          sx={{borderRadius: 5, minWidth: '155px',  width: {md: '20%', xs: '100%'}, fontWeight: 'bold', mb: {xs: 1, md: 2}, ml: 1}}
        >
          ログインする
        </Button>
      </Box>
    </Box>
  )
}