import {client} from "./client"
import Cookies from 'js-cookie';

export const postVerificaitons = (is_development: boolean) => {
  if(is_development){
    return client.post(`/ekyc_development/verifications?user_id=${Cookies.get('currentUserId')}`, {
      headers: {
        "access-token": Cookies.get("_access_token"),
        client: Cookies.get("_client"),
        uid: Cookies.get("_uid"),
      },
    });
  }else{
    return client.post(`/ekyc/verifications?user_id=${Cookies.get('currentUserId')}`, {
      headers: {
        "access-token": Cookies.get("_access_token"),
        client: Cookies.get("_client"),
        uid: Cookies.get("_uid"),
      },
    });
  }
}

export const putComparingData = (publicId: string, is_development: boolean, is_passport: boolean) => {
  if(is_development){
    return client.put(`/ekyc_development/comparing_data?user_id=${Cookies.get('currentUserId')}&public_id=${publicId}&is_passport=${is_passport}`, {
      headers: {
        "access-token": Cookies.get("_access_token"),
        client: Cookies.get("_client"),
        uid: Cookies.get("_uid"),
      },
    });
  }else{
    return client.put(`/ekyc/comparing_data?user_id=${Cookies.get('currentUserId')}&public_id=${publicId}&is_passport=${is_passport}`, {
      headers: {
        "access-token": Cookies.get("_access_token"),
        client: Cookies.get("_client"),
        uid: Cookies.get("_uid"),
      },
    });
  }
}