import {client} from "./client"
import Cookies from 'js-cookie';

// 動作確認用
export const getCampaign = (code: string) => {
  return client.get("/campaign?campaign_code=" + code, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    }
  });
}