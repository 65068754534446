import {client} from "./client"
import {Input} from '../../common/opportunity';
import dayjs from "dayjs";
import Cookies from 'js-cookie';

interface examination{
  applicantName: string;
  desiredAmount: number;
  user_id: string;
}

interface FileInput{
  seikyuusyo: FileList;
  tsuutyou: FileList;
  otherFile: FileList;
}

export const getOpportunities = () => {
  return client.get(`/opportunity?user_id=${Cookies.get('currentUserId')}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  })
}

export const getDraftOpportunity = () =>{
  // とりあえず見込みになっているものを下書き状態とする
  return client.get(`/opportunity?user_id=${Cookies.get('currentUserId')}&stagename=下書き(仮)`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  })
}

export const getOpportunityById = (id: string) =>{
  return client.get(`/opportunity/${id}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const createOpportunity = (params: Input) =>{
  const formData = createSendData(params);
  formData.append('opportunity[stagename]', '申請中');
  formData.append('opportunity[reviewrequestdate]', dayjs(Date()).format('YYYY-MM-DD'));
  return client.post('/opportunity', formData, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const cancelOpportunity = (id: string, reason: string, bikou: string) =>{
  const formData = new FormData();
  formData.append('opportunity[stagename]', 'キャンセル');
  formData.append('opportunity[condition]', 'キャンセル');
  formData.append('opportunity[finalresultdetail]', 'ポータルキャンセル');
  formData.append('opportunity[iscancelformportal]', 'true');
  formData.append('opportunity[cancelReason]', reason);
  formData.append('opportunity[cancelBikou]', bikou);
  return client.put(`/opportunity/${id}`, formData, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const updateExamination = (params: examination, id: string) =>{
  return client.put(`/opportunity/${id}`, params, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const uploadOpportunityFile = (id: string, params: FileInput) =>{
  const formData = new FormData();
  for(let i=0; i < params.seikyuusyo.length; i++){
    formData.append('add_files[]', params.seikyuusyo[i]);
  }
  for(let i=0; i < params.tsuutyou.length; i++){
    formData.append('add_files[]', params.tsuutyou[i]);
  }
  for(let i=0; i < params.otherFile.length; i++){
    formData.append('add_files[]', params.otherFile[i]);
  }
  return client.post(`/opportunity/${id}/file`, formData, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const getOpportunityFilesInfo = (id: string) =>{
  return client.get(`/opportunity/${id}/files`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const getFile = (id: string) =>{
  return client.get(`/s3file/${id}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

const createSendData = (params: Input) =>{
  const formData = new FormData();
  formData.append('user_id', params.user_id);
  formData.append('opportunity[name]', params.closedate.format('YYYY-MM-DD'));
  formData.append('opportunity[closedate]', params.closedate.format('YYYY-MM-DD'));
  formData.append('opportunity[type]', params.type);
  formData.append('opportunity[kiboukingaku_suchi]', params.kiboukingakuSuchi.replace(/,/g, ''));
  formData.append('opportunity[note]', params.goyoubou);
  formData.append('opportunity[campaign_code]', params.campaignCode);
  const total = params.companies.reduce((acc, comp) => {
    acc += parseInt(comp.seikyuukingaku.replace(/,/g, ''));
    return acc;
  }, 0);
  const maxDate = params.companies.reduce((acc, comp) =>{
    if(!acc && comp.kaisyuyoteibi) acc = comp.kaisyuyoteibi;
    else if(acc && acc < comp.kaisyuyoteibi) acc = comp.kaisyuyoteibi;
    return acc;
  }, null);

  params.companies.forEach((comp, index) =>{
    if(comp.seikyuusyo){
      for(let i=0; i < comp.seikyuusyo.length; i++){
        formData.append(`opportunity[invoices][]`, comp.seikyuusyo[i]);
      }
    }
    if(comp.company){
      formData.append(`opportunity[opp_acc_receivable_infos_attributes[${index}][herokuaccountsreceivableinfo]]`, comp.sfid);
      formData.append(`opportunity[opp_acc_receivable_infos_attributes[${index}][salesflg]]`, 'true');
    }
  });

  const compIdx = params.companies.length;
  params.otherCompanies.forEach((comp, index) =>{
    if(comp.company){
      formData.append(`opportunity[opp_acc_receivable_infos_attributes[${compIdx + index}][herokuaccountsreceivableinfo]]`, comp.sfid);
    }
  });

  formData.append('opportunity[totalamountofreceivables]', total.toString());
  if(maxDate) formData.append('opportunity[kaisyuyoteibi]', dayjs(maxDate).format('YYYY-MM-DD'));
  if(params.tsuutyou){
    for(let i=0; i < params.tsuutyou.length; i++){
      formData.append(`opportunity[passbooks][]`, params.tsuutyou[i]);
    }
  }
  if(params.otherFile){
    for(let i=0; i < params.otherFile.length; i++){
      formData.append(`opportunity[other_files][]`, params.otherFile[i]);
    }
  }
  return formData;
}
