import React, { useState } from "react";
import { Link } from "react-router-dom";
import { confirmReset } from "../../lib/api/auth";
import { Button, TextField, Container, Grid, InputLabel, Box, Link as MuiLink, Typography } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import Loading from "../../components/Loading";
import { ErrorModal } from "../../components/ErrorModal";

export const PasswordConfirm = () =>{
  const { control, handleSubmit, setValue, getValues} = useForm({defaultValues: {email: ''}});
  const [isLoading, setIsLoading] = useState(false);
  const [confirmed, setConfirm] = useState(true);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const handleSignInSubmit = async (e) => {
    if(isLoading) return;
    setIsLoading(true);
    try {
      const res = await confirmReset(e);
      setIsLoading(false);
      setErrorMessage(<Typography>ユーザ認証用のメールを送信しました。</Typography>);
      handleOpenErrorModal();
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      setErrorMessage(<Typography>{e.response.data.errors[0]}</Typography>);
      handleOpenErrorModal();
    }
  };
  return (
    <Container
      sx={{
        width: {md: '80%', xs: '95%'},
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        border: 'solid',
        borderColor: '#E1DED0',
        pt: 5
      }}
    >
      <form id='password_reset_form' onSubmit={handleSubmit(handleSignInSubmit)}>
        <Controller
          control={control}
          name='email'
          render={({ field, fieldState }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  メールアドレス
                </InputLabel>
              </Grid>
              <Grid item md={9} sm={12} xs={12}>
                <TextField
                  {...field}
                  fullWidth
                  sx={{mt: {xs: 0, md: 2}}}
                  margin="normal"
                  color="secondary"
                  placeholder="入力してください"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              </Grid>
            </Grid>
          )}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          送信
        </Button>
      </form>
      <MuiLink
        component={Link}
        to="/login"
        underline="hover"
      >ログインする</MuiLink>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Container>
  );
}
