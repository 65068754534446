import React, {useEffect, useLayoutEffect, useState} from "react";
import { Modal, Box, Hidden, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography} from '@mui/material';
import { createFixedPhrase } from "../lib/api/fixedPhrase";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Cookies from 'js-cookie';

interface fixedPhrase {
  phrase: string;
  user_id: string;
}

const phraseStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 430,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

export const ErrorModal = (props) =>{
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  useLayoutEffect(() =>{
    setOpen(!open);
  }, [props.modalOpen])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={phraseStyle}>
        {props.message}
        <Box sx={{marginTop: '10px'}}>
        <Button
          variant='contained'
          color='warning'
          onClick={() => handleClose()}
          sx={{borderRadius: 5, width: '100%', fontWeight: 'bold'}}
        >
          閉じる
        </Button>
        </Box>
      </Box>
    </Modal>
  )
}