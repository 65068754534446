import React, {useState, useEffect} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { uploadOpportunityFile } from "../../lib/api/opportunity"
import { Box, Button, Hidden, Typography} from '@mui/material';
import Loading from "../../components/Loading";
import { ErrorModal } from '../../components/ErrorModal';

interface fileInfo {
  seikyuusyo: FileList;
  tsuutyou: FileList;
  otherFile: FileList;
}

interface FileFormInfo {
  seikyuusyo: FileList;
  tsuutyou: FileList;
  otherFile: FileList;
}

export const FileuploadConfirm = () =>{
  const location = useLocation();
  const [fileInfo, setFileInfo] = useState<fileInfo>(location.state);
  const { control, handleSubmit, setValue} = useForm<FileFormInfo>();
  const navigate = useNavigate();
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  useEffect(() => {
    setValue('seikyuusyo', fileInfo.seikyuusyo);
    setValue('tsuutyou', fileInfo.tsuutyou);
    setValue('otherFile', fileInfo.otherFile);
  });

  const onClickCancel = () =>{
    navigate(`/file_addition/${id}`, {state: fileInfo});
  }

  const onSubmit = async (evt) =>{
    if(id == null) return; 
    if(isLoading) return;
    setIsLoading(true);
    try{
      const res = await uploadOpportunityFile(id, evt);
      setIsLoading(false);
      if(res.status === 200){
        navigate(`/file_addition/completed`, {state: id});
      }
    }catch(e){
      console.log(e);
      setIsLoading(false);
      setErrorMessage(<Typography>{e.response.data.errors[0]}</Typography>);
      handleOpenErrorModal();
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: '#F0EFEA',
        width: {md: '60%'},
        height: {md: '200px'},
        margin: '0 auto',
        borderRadius: '5px',
        minWidth: {md: '570px'},
        mt: {md: 10, xs: 2},
        pr: 2,
        pl: 2,
        pt: 1,
        pb: 1
      }}
    >
      <Typography sx={{fontWeight: 'bold', fontSize: 17, pt: 5}}>
        この内容で書類を提出しますか？
      </Typography>
      <form id="file-confirm-form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            control={control}
            name="seikyuusyo"
            disabled
            render={({ field }) => (
              <Hidden
              />
            )}
          />
          <Controller
            control={control}
            name="tsuutyou"
            disabled
            render={({ field }) => (
              <Hidden
              />
            )}
          />
          <Controller
            control={control}
            name="otherFile"
            disabled
            render={({ field }) => (
              <Hidden
              />
            )}
          />
          <Box sx={{mt: 7}}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => onClickCancel()}
              sx={{borderRadius: 5, width: '30%', fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
            >
              戻る
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{borderRadius: 5, width: '30%', fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
            >
              次へ
            </Button>
          </Box>
        </div>
      </form>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Box>
  )
}