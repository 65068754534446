import dayjs, {Dayjs} from 'dayjs';

export interface Company{
  company: string;
  sfid: string;
  seikyuukingaku: string;
  kaisyuyoteibi: any;
  seikyuusyo: any;
}

export interface CancelCompany{
  company: string;
  sfid: string;
  seikyuukingaku: number;
  kaisyuyoteibi: any;
  seikyuusyo: any;
}

export interface OtherCompany{
  company: string;
  sfid: string;
}

export interface Input{
  user_id: string;
  closedate: any;
  kiboukingakuSuchi: string;
  type: string;
  tashariyoureki: string;
  riyoucompany: string;
  tsuutyou: any;
  otherFile: any;
  goyoubou: string;
  companies: Company[];
  otherCompanies: OtherCompany[];
  campaignCode: string;
}

export interface InputExam{
  user_id: string;
  closedate: any;
  kiboukingakuSuchi: string;
  type: string;
  tashariyoureki: string;
  riyoucompany: string;
  goyoubou: string;
  company: string;
  companyCount: number;
  seikyuukingaku: string;
  kaisyuyoteibi: any;
  id: string;
}

export interface InputCancelExam{
  cancelReason: string;
  cancelBikou: string;
  user_id: string;
  closedate: any;
  kiboukingakuSuchi: string;
  type: string;
  tashariyoureki: string;
  riyoucompany: string;
  goyoubou: string;
  company: string;
  companyCount: number;
  seikyuukingaku: string;
  kaisyuyoteibi: any;
  id: string;
}

export const defaultCompValue: Company ={
  company: '',
  sfid: '',
  seikyuukingaku: '',
  kaisyuyoteibi: dayjs(Date()),
  seikyuusyo: null
}

export const defaultOtherCompValue: OtherCompany ={
  company: '',
  sfid: '',
}
  
export const defaultValues: Input ={
  user_id: '',
  closedate: dayjs(Date()),
  kiboukingakuSuchi: '',
  type: '',
  tashariyoureki: '',
  riyoucompany: '',
  tsuutyou: null,
  otherFile: null,
  goyoubou: '',
  companies: [defaultCompValue],
  otherCompanies: [defaultOtherCompValue],
  campaignCode: ''
}

export const defaultExamValues: InputExam ={
  user_id: '',
  closedate: dayjs(Date()),
  kiboukingakuSuchi: '',
  type: '',
  tashariyoureki: '',
  riyoucompany: '',
  goyoubou: '',
  company: '',
  companyCount: 0,
  seikyuukingaku: '',
  kaisyuyoteibi: dayjs(Date()),
  id: ''
}

export const defaultCancelExamValues: InputCancelExam ={
  cancelReason: '',
  cancelBikou: '',
  user_id: '',
  closedate: dayjs(Date()),
  kiboukingakuSuchi: '',
  type: '',
  tashariyoureki: '',
  riyoucompany: '',
  goyoubou: '',
  company: '',
  companyCount: 0,
  seikyuukingaku: '',
  kaisyuyoteibi: dayjs(Date()),
  id: ''
}

export interface FactoringInfo {
  inputs: Input;
  type: string;
  editId: string;
}

export interface FileInfo {
  filename: string;
  key: string;
  id: string;
}

export function createFileInfo(
  filename: string,
  key: string,
  id: string
): FileInfo{
  return {filename, key, id}
}

export function mappingExamOpportunity(record){
  return {
    id: record.opportunity.id,
    closedate: dayjs(record.opportunity.closedate),
    kiboukingakuSuchi: record.opportunity.kiboukingakuSuchiC,
    type: record.opportunity.typeC,
    tashariyoureki: record.account.tashariyourekiC,
    riyoucompany: record.account.othercompaniesusedinthepastC,
    goyoubou: record.opportunity.noteC,
    user_id: '',
    company: '',
    companyCount: 0,
    seikyuukingaku: record.opportunity.totalmountofreceivablesC,
    kaisyuyoteibi: dayjs(record.opportunity.kaisyuyoteibiC)
  }
}

export const validationRules = {
  closedate:{
    required: '調達希望日を入力してください。'
  },
  kiboukingakuSuchi:{
    maxLength: {
      value: 18,
      message: '18桁以下で入力してください。'
    },
    required: '調達希望金額を入力してください',
    validate: (value, formValues)  =>{
      const num = value.replace(/,/g, '');
      const NumPattern = /^[1-9][0-9]*$/;
      if(!NumPattern.test(num)){
        return '数値のみ入力できます。'
      }
      if(parseInt(num) < 10000){
        return '10,000以上で入力してください。'
      }
      const sumValue = formValues.companies.reduce((acc, val, index) =>{
        const tmp = val.seikyuukingaku.replace(/,/g, '');
        if(NumPattern.test(tmp)){
          acc += parseInt(tmp);
        }
        return acc;
      }, 0);
      if(sumValue < parseInt(num)){
        return '売掛先情報の請求金額の合計金額より多い金額は調達できません。'
      }
      if(parseInt(num) > 999999999){
        return '1,000,000,000未満で入力してください。'
      }
    }
  },
  goyoubou: {
    maxLength: {
      value: 2000,
      message: '2000文字以下で入力してください。'
    }
  },
  type:{
    required: '希望契約方法を入力してください。'
  },
  tashariyoureki:{
    required: '他社利用歴を入力してください。'
  },
  company:{
    required: '会社名を入力してください。',
    validate: (value, formValues) =>{
      const comps = formValues.companies.reduce((acc, c) => {
        acc[c.company] = c.sfid;
        return acc;
      }, {})
      if(!comps[value]) return '会社名を入力してください。'
    }
  },
  otherCompany:{
    validate: (value, formValues) =>{
      const comps = formValues.otherCompanies.reduce((acc, c) => {
        acc[c.company] = c.sfid;
        return acc;
      }, {})
      if(value && !comps[value]) return '会社名を入力してください。'
    }
  },
  seikyuukingaku:{
    required: '請求金額を入力してください。',
    validate: (value, formValues)  =>{
      const num = value.replace(/,/g, '');
      const NumPattern = /^[1-9][0-9]*$/;
      if(!NumPattern.test(num)){
        return '数値のみ入力できます。'
      }
      if(parseInt(num) > 999999999){
        return '1,000,000,000未満で入力してください。'
      }
    }
  },
  kaisyuyoteibi:{
    required: '売掛先からの入金予定日を入力してください。'
  },
  seikyuusyo:{
    required: '請求書を入力してください。',
    validate: (value, formValues) =>{
      if(value && value.length == 0) return '請求書を入力してください。'
    }
  },
  tsuutyou:{
    required: '通帳を入力してください。',
    validate: (value, formValues) =>{
      if(value && value.length == 0) return '通帳を入力してください。'
    }
  }
}

export const statusColors = {
  '審査中': "#ED7D31",
  '契約手続き中': "#548235",
  '否決': "#767171",
  'キャンセル': '#203864',
  '契約済み': '#70AD48',
  '要確認' : '#FC0201',
  '取引完了': "#ED7D31",
  '入金予定日前':'#FC0201'
}

export const searchStatus = [
  '審査中',
  '契約手続き中',
  '否決',
  'キャンセル',
  '契約済み'
]