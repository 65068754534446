import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

export const FooterText = () =>{
    const location = useLocation();
    const [isShow, setIsShow] = useState(false);
    useEffect(() =>{
    if(location.pathname == '/profile'){
        setIsShow(true);
    }else{
        setIsShow(false);
    }
    }, [location.pathname])

    return (
        <Box>
            { isShow ? <Box>
                <Typography sx={{display: {xs: 'none', md: 'block'}, fontSize: '10px', mt: 3, textAlign: 'left'}}>※お客様からのご要望や最新の登記情報に基づき、会員情報または売掛先情報を当社が変更する場合がございます。</Typography>
                <Typography sx={{display: {xs: 'none', md: 'block'}, fontSize: '10px', mb: 1, textAlign: 'left', pl: 1.3}}>更新された内容に異議等がある場合は<Box component='a' sx={{wordBreak: 'break-all'}}  className='link'　target='_blank' href='https://betrading.jp/company/contact/'>こちら</Box>よりお問い合わせください。</Typography>
            </Box> : <></>
            }
        </Box>
    )
}