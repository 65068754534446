import {client} from "./client"
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import {Input} from "../../common/accounts_receivable"

interface Corporate {
  name: string;
}

export const getCorporates = (params: Corporate) => {
  return client.get(`/guest/corporate_name/search?name=${params.name}`);
}

export const getAccountsReceivable = (id: string, acc: any) =>{
  return client.get(`/guest/accounts_receivable/${id}?account=${acc}`);
}

export const getAccountsReceivables = (acc: any) => {
  return client.get(`/guest/accounts_receivable?account=${acc}`);
}

export const createAccountsReceivable = (params: Input, acc: any) =>{
  const formData = new FormData();
  if(params.kind) formData.append('accounts_receivable[type]', params.kind);
  if(params.hpAddress) formData.append('accounts_receivable[hp]', params.hpAddress);
  if(params.houjinNo) formData.append('accounts_receivable[corporationnumber]', params.houjinNo);
  if(params.companyName){
    formData.append('accounts_receivable[name]', params.companyName);
    formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][companyname]]', params.companyName)
  }
  if(params.postalcode){
    formData.append('accounts_receivable[postalcode]', params.postalcode);
    formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][postalcode]', params.postalcode);
  }
  if(params.state){
    formData.append('accounts_receivable[state]', params.state);
    formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][state]', params.state);
  }
  if(params.city){
    formData.append('accounts_receivable[city]', params.city);
    formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][city]', params.city);
  }
  if(params.street){
    formData.append('accounts_receivable[street]', params.street);
    formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][street]', params.street);
  }
  if(params.other){
    formData.append('accounts_receivable[other]', params.other);
    formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][other]', params.other);
  }
  if(params.fixDate) formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][closingdate]]', params.fixDate);
  if(params.paymentDate) formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][paymentdate]]', params.paymentDate);
  if(params.manager) formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][personincharge]]', params.manager);
  if(params.phone) formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][telno]]', params.phone);
  formData.append('accounts_receivable[accounts_receivable_infos_attributes[0][registrationdate]]', dayjs().toISOString());
  formData.append('sfid', acc);
  return client.post('/guest/accounts_receivable', formData);
}