import {client} from "./client"
import Cookies from 'js-cookie';

// 動作確認用
export const getLeads = () => {
  return client.get("/lead")
}

export const getLeadById = () =>{
  return client.get(`/lead/${Cookies.get('currentUserId')}`, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}

export const updateLead = (params: any) =>{
  return client.put(`/lead/${Cookies.get('currentUserId')}`, params, {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
}
