import React from 'react';
import {Box, Divider, Typography } from '@mui/material';
import { ThreeP } from '@mui/icons-material';

export const PrivacyPolicy = () =>{
  return (
    <Box sx={{textAlign: 'left', backgroundColor: "#FFFFFF", p: 2, borderRadius: 2}}>
      <Typography >株式会社ビートレーディング（以下「当社」）は、個人情報（氏名、住所、生年月日、電話番号、その他の個人情報）の重要性を認識し、以下のガイドラインに基づいて、適切な取り扱いと保護の徹底に努めます。</Typography>
      <Typography >当社ホームページのご利用にあたり、お客様には当プライバシーポリシーにご同意いただいたものとさせていただきます。</Typography>
      <Typography sx={{fontSize: 18}}>宣言</Typography>
      <Typography >当社は、ここに個人情報保護方針を定め公開し、スタッフ並び当社関係者に個人情報保護の重要性と認識に取組み、徹底させることにより、個人情報の保護を推進致します。</Typography>
      <Typography sx={{fontSize: 18}}>個人情報の管理</Typography>
      <Typography >当社は、個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行います。</Typography>
      <Typography sx={{fontSize: 18}}>個人情報の利用目的</Typography>
      <Typography >当社における個人情報の利用目的は、以下のとおりです。</Typography>
      <Typography >なお、当社はお客様とのご契約終了後や会員サイト退会後も、以下の利用目的の範囲内で個人情報を利用することがあります。</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
        ファクタリングサービスのお申込み、ご相談の受付のため
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        ご本人様の確認や、ファクタリングサービスをご利用いただく資格等（反社会的勢力との関係を含みます）の確認のため
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        ファクタリングサービスの提供にかかる妥当性判断のため
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        お客様とのご契約について、当社においてそのご契約の管理を適切に行うため
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        ご契約終了後の照会への対応や法令等により必要となる管理を適切に行うため
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        お客様とのご契約や法律等に基づく権利の行使や義務の履行のため
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        譲受債権の管理・回収、権利行使、分析、査定のため
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        電話によるご案内やダイレクトメールの発送等、サービスに関する各種ご案内のため
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        各種リスク管理を適切に行うため
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        お客様によりよいファクタリングサービスを提供するためなど、よりご満足をいただくためのマーケティング分析に利用するた
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        当社や第三者の商品又はサービスに関する広告を配信若しくは表示するため
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        関連会社との共同利用のため
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        その他、お客さまとのお取引を適切かつ円滑に履行するため
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>個人データの第三者提供</Typography>
      <Typography>当社は、お客様の同意を得ないで個人データを第三者に提供しません。ただし、次に掲げる場合を除きます。</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
        法令に基づく場合
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        人の生命、身体又は財産の保護のために必要がある場合（当社の財産保護のため必要がある場合を含みます。）であって、本人の同意を得ることが困難であるとき
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>個人データの共同利用</Typography>
      <Typography>当社は、以下のとおり個人データを共同利用することがあります。</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
        共同して利用する者の範囲<br/>当社の子会社及び関連会社　※対象会社は、変動します。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          共同利用者の利用目的<br/>共同利用者は、以下の目的で利用します。
          <Box component='ol' className='seven_ol'>
            <Box component='li' className='seven_li'>当社、子会社及び関連会社における譲受債権の管理・回収、権利行使、分析、査定、お客様との契約管理、リスクの掌握等、経営上必要な各種の管理を行うため</Box>
            <Box component='li' className='seven_li'>お客様によりよい商品、サービスを提供し、よりご満足をいただくためのマーケティング分析や商品・サービス開発を行うため</Box>
            <Box component='li' className='seven_li'>当社、子会社及び関連会社のサービスのご紹介・ご提案のため</Box>
          </Box>
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          共同利用する個人データ項目
          <Box component='ol' className='seven_ol'>
            <Box component='li' className='seven_li'>属性情報（名前、住所、生年月日、電話番号、メールアドレス等）</Box>
            <Box component='li' className='seven_li'>取引情報（契約の種類、契約日、契約金額、履行状況等）</Box>
            <Box component='li' className='seven_li'>譲受債権の情報（譲受債権の種類、金額、残高、支払状況等）</Box>
            <Box component='li' className='seven_li'>収支関連情報（収入、資産、決算関連事項）</Box>
            <Box component='li' className='seven_li'>共同利用者が一定の基準により査定を行った評価情報など</Box>
          </Box>
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
          個人データの管理について責任を有する者
          <Box component='ol' sx={{listStyleType: 'none'}}>
            <Box component='li' sx={{textAlign: 'left'}}>〒105-0012　東京都港区芝大門一丁目2-18　野依ビル 3階・4階</Box>
            <Box component='li' sx={{textAlign: 'left'}}>株式会社ビートレーディング　代表取締役　佐々木英世</Box>
          </Box>
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>保有個人データに関する開示、訂正、利用停止等</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
        お客様は、お客様に関する当社の保有個人データの開示を求めることができます。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        当社の保有個人データの内容に誤りがある場合、お客様は当該保有個人データの内容の訂正、追加又は削除を求めることができます。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        当社の保有個人データについて当社が違法に取得又は違法に扱っている場合、お客様は当該保有個人データの利用の停止、消去を求めることができます。
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        お申出先は、以下のとおりです。<br/>
        〒105-0012　東京都港区芝大門一丁目2-18　野依ビル 3階・4階<br/>
        株式会社ビートレーディング<br/>
        <Box component='a' href='mailto:mail@betrading.co.jp'>mail@betrading.co.jp</Box><br/>
        （お申出は、ご本人、法定代理人、委任を受けた代理人に限りすることができます。お申出にあたっては、お客様ご本人であることの確認のため、免許証等の本人確認書類（代理人にあっては委任状及びご本人の印鑑証明書、成年被後見人にあっては登記事項証明書、未成年者にあっては本人の戸籍謄本）のご提出またはご提示が必要となります。）
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>個人関連情報の受領</Typography>
      <Typography>当社は、その情報単体では個人情報に該当しない個人関連情報を第三者から取得しています。</Typography>
      <Typography>お客様が当社のサービスの利用にあたり当社に個人情報を提供した場合、当社は、当該情報と、当該お客様の個人関連情報を紐付ける場合があります。</Typography>
      <Typography>この場合には当該個人関連情報も個人情報として取り扱います。</Typography><br/>
      <Typography>当社は、個人関連情報を、当プライバシーポリシーにおける「個人情報の利用目的」と同様の目的に利用します。</Typography>
      <Typography>ここで、個人関連情報とは、以下の情報を言います。</Typography>
      <Box component='ol' sx={{listStyleType: 'decimal'}}>
        <Box component='li' sx={{textAlign: 'left'}}>
        属性情報（名前、住所、生年月日、電話番号、メールアドレス等）
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        Cookie
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        IPアドレス
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        識別子
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        使用端末・ブラウザ（インターネット閲覧ソフト）
        </Box>
        <Box component='li' sx={{textAlign: 'left'}}>
        位置情報・行動履歴といったインターネットの利用にかかるログ情報
        </Box>
      </Box>
      <Typography sx={{fontSize: 18}}>個人情報の安全対策</Typography>
      <Typography>当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。</Typography>
      <Typography sx={{fontSize: 18}}>法令、規範の遵守と見直し</Typography>
      <Typography>当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。</Typography>
      <Typography sx={{fontSize: 18}}>お問い合わせ</Typography>
      <Typography>当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。</Typography>
      <Typography>株式会社ビートレディング</Typography>
      <Typography><Box component='a' href='mailto:mail@betrading.co.jp'>メールでのお問い合わせ</Box></Typography>
    </Box>
  );
};