import React from 'react';
import Router from './Router';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';

const App = () =>{
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
      <CssBaseline />
      <Router />
      </div>
    </ThemeProvider>
  );
}

export default App;
